import React, { useRef, useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Container,
  Col,
} from "reactstrap";
import { connect, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import moment from "moment";
import * as Yup from "yup";
import debounce from "lodash.debounce";
import Select2 from "../../components/forms/Select2";
import Input from "../../components/forms/Input";
import ButtonModal from "../../components/modal/ButtonModal";
import Textarea from "../../components/forms/Textarea";
import Select2Multi from "../../components/forms/Select2Multi";
import DynamicButton from "../../components/forms/DynamicButton";
import ConfirmationModal from "../../components/modal/ConfirmModal";
import { withTrans } from "../../i18n/withTrans";
import { toastError } from "../../components/commons/toast";
import MasterLoungeService from "../../store/actions/master_data/lounge";
import { defaultUploadImgProp, promoLoungeType } from "../../utils/Constants";

const FormPromoModal = ({
  t,
  show,
  toggle,
  actionAdd,
  actionEdit,
  data,
  dataAirport,
  confirm,
  data_post,
  data_put,
  error_message,
}) => {
  const title = (data ? t("commons.edit") : t("commons.add")) + " " + "Promo";
  const dispatch = useDispatch();
  const formikRef = useRef();
  const fileRef = useRef();
  const [searchLounge, setSearchLounge] = useState("");
  const [dataLounges, setDataLounges] = useState([]);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [image, setImage] = useState(null);
  const [fileProp, setFileProp] = useState(defaultUploadImgProp);
  const [selectedAirportIds, setSelectedAirportIds] = useState([]);

  useEffect(() => {
    if (data && data?.list_airports_detail) {
      setSelectedAirportIds(
        data?.list_airports_detail
          ?.map((item, index) => item?.id)
          .join()
          .split(",")
      )
    }
  }, [data]);

  useEffect(() => {
    if (!selectedAirportIds?.length) return;

    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchLounge,
        airport_ids: selectedAirportIds
      };
      dispatch(MasterLoungeService.get(param, resolve));
    }).then((res) => {
      if (res) {
        setDataLounges(
          res?.data?.map((item) => ({
            label: item?.name,
            value: item?.id
          }))
        )
      }
    });
  }, [selectedAirportIds, searchLounge]);

  useEffect(() => {
    if (!error_message) return;

    setLoadingSubmit(false);
    toastError(error_message);
  }, [error_message]);

  useEffect(() => {
    if (data_post?.data?.status === 200) {
      setLoadingSubmit(false);
      setFileProp(defaultUploadImgProp);
    }
  }, [data_post]);

  useEffect(() => {
    if (data_put?.data?.status === 200) {
      setLoadingSubmit(false);
      setFileProp(defaultUploadImgProp);
    }
  }, [data_put]);

  const debouncedSearchLounge = debounce((value) => {
    setSearchLounge(value);
  }, 500);

  const initial = data
    ? {
      id: data?.id,
      name: data?.name,
      code: data?.code,
      type: data?.type,
      is_service_date_period: data?.is_service_date_period,
      period_end: moment(data?.period_end).format("YYYY-MM-DD"),
      period_start: moment(data?.period_start).format("YYYY-MM-DD"),
      quantity: data?.quantity,
      description: data?.description,
      discount_percent: data?.discount_percent,
      discount_price: data?.discount_price,
      promo_type: data?.discount_price === 0 ? 0 : 1,
      banner_file_path: data?.banner,
      airport_ids: data?.list_airports_detail
        ? data?.list_airports_detail?.map((item, index) => item?.id).join().split(',')
        : "",
      list_lounges: data?.list_lounge_detail
        ? data?.list_lounge_detail?.map((item, index) => item?.id).join().split(',')
        : "",
    }
    : {
      airport_ids: "",
      banner: "",
      code: "",
      name: "",
      description: "",
      discount_price: "",
      discount_percent: "",
      order_number: 0,
      type: "",
      list_lounges: "",
      quantity: "",
      is_service_date_period: null,
      period_end: "",
      period_start: "",
      promo_type: "",
    };

  const handleConfirm = () => {
    setFileProp(defaultUploadImgProp);
    setShowModalConfirm(false);
    toggle(false);
  };

  const validation = Yup.object().shape({
    name: Yup.string().required(`${t("promo.promoName")} ${t("commons.required")}`).nullable(),
    description: Yup.string().required(`${t("promo.description")} ${t("commons.required")}`).nullable(),
    is_service_date_period: Yup.boolean().required(`${t("promo.typePeriodPromo")} ${t("commons.required")}`).nullable(),
    period_end: Yup.string().required(`${t("promo.endDate")} ${t("commons.required")}`).nullable(),
    period_start: Yup.string().required(`${t("promo.startDate")} ${t("commons.required")}`).nullable(),
    type: Yup.string().required(`${t("promo.promoType")} ${t("commons.required")}`).nullable(),
    promo_type: Yup.string().required(`${t("promo.promoValue")} ${t("commons.required")}`).nullable(),
    quantity:
      Yup.string().when('type', {
        is: 'specific',
        then: (d) => d.required(`${t("promo.numberOfPromo")} ${t("commons.required")}`).nullable()
      }),
    code:
      Yup.string().when('type', {
        is: 'blast',
        then: (d) => d.required(`${t("promo.promoCode")} ${t("commons.required")}`).nullable()
      }),
    discount_price:
      Yup.string().when('promo_type', {
        is: '1',
        then: (d) => d.required(`${t("promo.promoValue")} ${t("commons.required")}`).nullable()
      }),
    discount_percent:
      Yup.string().when('promo_type', {
        is: '0',
        then: (d) => d.required(`${t("promo.promoValue")} ${t("commons.required")}`).nullable()
      }),
    airport_ids: Yup.array().required(`${t("field.airport")} ${t("commons.required")}`).nullable(),
    list_lounges: Yup.array().required(`${t("field.lounge")} ${t("commons.required")}`).nullable(),
  });

  const openFileDialog = () => {
    fileRef.current.click();
  };

  const handleChange = (selectorFiles, formik) => {
    if (Number(selectorFiles.files[0].size) < 2097152) {
      formik.setFieldValue("banner_file", selectorFiles.files[0]);
      const img = new Image()
      img.src = window.URL.createObjectURL(selectorFiles.files[0])
      img.onload = () => {
        setFileProp({
          title: selectorFiles.files[0].name,
          subtitle:
            `Size: ${(Number(selectorFiles.files[0].size) * 0.001).toFixed(2)} KB`,
          description: `Dimensions: ${img.width} x ${img.height} px`,
          message_error: false,
        });
      }
    } else {
      toastError("Maksimal file yang dapat diimport 2 MB");
    }
  };

  useEffect(() => {
    if (data?.banner) {
      convertImgToBase64URL(
        process.env.REACT_APP_API_URL +
        data?.banner.substr(1, data?.banner?.length),
        function (base64Img) {
          const name = data?.banner.split("/");

          var arr = base64Img.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }

          const file = new File([u8arr], name[name.length - 1], { type: mime });
          setImage(file);
          const img = new Image();
          img.src = window.URL.createObjectURL(file)
          img.onload = () => {
            setFileProp({
              title: file.name,
              subtitle:
                `Size: ${(Number(file.size) * 0.001).toFixed(2)} KB`,
              description: `Dimensions: ${img.width} x ${img.height} px`,
              message_error: false,
            });
          }
        }
      );
    }
  }, [data?.banner]);

  const convertImgToBase64URL = (url, callback) => {
    var img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = function () {
      var canvas = document.createElement("CANVAS"),
        ctx = canvas.getContext("2d"),
        dataURL;
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      dataURL = canvas.toDataURL("image/jpeg");
      callback(dataURL);
      canvas = null;
    };
    img.src = url;
  };

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {title}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          initialValues={initial}
          validationSchema={validation}
          enableReinitialize
          onSubmit={(values, { setStatus }) => {
            setStatus();
            if (
              values.type === promoLoungeType.Blast &&
              !(values?.banner_file || values?.banner_file_path)
            ) {
              setLoadingSubmit(false);
              setFileProp(defaultUploadImgProp);
              return;
            }

            let formData = new FormData();
            if (values.type === promoLoungeType.Blast) {
              formData.append("code", values.code);
            }
            formData.append("description", values.description);
            if (values.promo_type === 1) {
              formData.append("discount_percent", 0);
              if (!Number.isInteger(values.discount_price)) {
                formData.append(
                  "discount_price",
                  Number(values.discount_price.replace(/[^0-9\.-]+/g, ""))
                );
              } else {
                formData.append("discount_price", values.discount_price);
              }
            } else {
              formData.append("discount_price", 0);
              formData.append("discount_percent", values.discount_percent);
            }
            formData.append("list_airports", values.airport_ids);
            formData.append("list_lounges", values.list_lounges);
            formData.append("name", values.name);
            formData.append("order_number", 0);
            formData.append("is_service_date_period", values?.is_service_date_period);
            formData.append("period_end", moment(values.period_end).format("YYYY-MM-DD HH:mm:ss"));
            formData.append("period_start", moment(values.period_start).format("YYYY-MM-DD HH:mm:ss"));
            formData.append("quantity", values.quantity ? values.quantity : 0);
            formData.append("type", values.type);
            setLoadingSubmit(true);

            if (data) {
              formData.append("id", values.id);
              if (values.banner_file) {
                formData.append("file", values?.banner_file);
              } else {
                formData.append("file", image);
              }
              actionEdit(formData)
            } else {
              formData.append("file", values?.banner_file);
              actionAdd(formData)
            }
          }}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <Container>
                  <Input
                    name="name"
                    errors={props.errors}
                    touched={props.touched}
                    {...props.getFieldProps("name")}
                    title={t("promo.promoName")}
                    type="text"
                  />
                  <Select2
                    title={t("promo.promoType")}
                    name="type"
                    options={[
                      {
                        value: "specific",
                        label: t("promo.special"),
                      },
                      {
                        value: "blast",
                        label: "Blast",
                      },
                    ]}
                    type="text"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps("type")}
                    value={props.values.type}
                    onChange={(name, value) => {
                      props?.setFieldValue("type", value);
                    }}
                  />
                  {props?.values?.type === promoLoungeType.Specific && (
                    <>
                      {" "}
                      <Input
                        name="quantity"
                        errors={props.errors}
                        touched={props.touched}
                        value={props.values.quantity}
                        {...props.getFieldProps("quantity")}
                        title={t("promo.numberOfPromo")}
                        type="number"
                        className="mt-1"
                        placeholder={t("promo.numberOfPromo")}
                      />
                    </>
                  )}
                  {props?.values?.type === promoLoungeType.Blast && (
                    <>
                      {" "}
                      <Input
                        name="code"
                        errors={props.errors}
                        touched={props.touched}
                        value={props.values.code}
                        {...props.getFieldProps("code")}
                        title={t("promo.promoCode")}
                        type="text"
                        className="mt-1"
                      />
                    </>
                  )}
                  <Row>
                    <Col md={12} className="pr-0">
                      <div
                        className="form-group mb-0 mt-1"
                      >
                        <label className="form-label">
                          {t("promo.promoValue")}
                        </label>
                      </div>
                    </Col>
                    <Col md={4} className="pr-0">
                      <Select2
                        name="promo_type"
                        options={[
                          {
                            value: 0,
                            label: `${t("promo.percentage")} (%)`,
                          },
                          {
                            value: 1,
                            label: `${t("promo.rupiah")} (Rp)`,
                          },
                        ]}
                        type="text"
                        errors={props?.errors}
                        touched={props?.touched}
                        {...props?.getFieldProps("promo_type")}
                        value={props.values.promo_type}
                        onChange={(name, value) => {
                          props?.setFieldValue("promo_type", value);
                        }}
                      />
                    </Col>
                    {props.values.promo_type === 0 ? (
                      <Col md={8} className="pl-0">
                        <Input
                          name="discount_percent"
                          errors={props.errors}
                          value={props.values.discount_percent}
                          touched={props.touched}
                          {...props.getFieldProps("discount_percent")}
                          type="number"
                          min="0"
                          max="100"
                          placeholder={t("promo.promoValue")}
                        />
                      </Col>
                    ) : (
                      <Col md={8} className="pl-1">
                        <Input
                          currency
                          errors={props.errors}
                          touched={props.touched}
                          thousandSeparator={true}
                          prefix={"Rp "}
                          onChangeValue={(e) => {
                            props.setFieldValue(
                              "discount_price",
                              e.target.value
                            );
                          }}
                          value={props.values.discount_price}
                          {...props.getFieldProps("discount_price")}
                          placeholder={t("promo.promoValue")}
                          name="discount_price"
                        />
                      </Col>
                    )}
                  </Row>
                  <Select2
                    title={t("promo.typePeriodPromo")}
                    name="is_service_date_period"
                    options={
                      [
                        {
                          value: true,
                          label: t("report.serviceDate"),
                        },
                        {
                          value: false,
                          label: t("report.bookingDate"),
                        }
                      ]
                    }
                    errors={props?.errors}
                    touched={props?.touched}
                    value={props.values.is_service_date_period}
                    onChange={(name, value) => {
                      props?.setFieldValue("is_service_date_period", value)
                      
                    }}
                  />
                  <Row className="mt-1">
                    <Col md={6}>
                      <Input
                        title={props?.values?.is_service_date_period
                          ? t("promo.serviceStartDate")
                          : t("promo.bookingStartDate")}
                        name="period_start"
                        type="date"
                        errors={props.errors}
                        touched={props.touched}
                        value={props.values?.period_start}
                        {...props?.getFieldProps("period_start")}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        title={props?.values?.is_service_date_period
                          ? t("promo.serviceEndDate")
                          : t("promo.bookingEndDate")}
                        name="period_end"
                        type="date"
                        min={props.values?.period_start}
                        errors={props.errors}
                        touched={props.touched}
                        value={props.values?.period_end}
                        {...props?.getFieldProps("period_end")}
                      />
                    </Col>
                  </Row>
                  <Select2Multi
                    title={t("field.airport")}
                    name="airport_ids"
                    options={[
                      {
                        label: `${t("commons.all")} ${t("field.airport")}`,
                        value: 'all',
                      },
                      ...dataAirport
                    ]}
                    className="mb-2"
                    errors={props?.errors}
                    touched={props?.touched}
                    value={props?.values?.airport_ids}
                    onChange={(name, value) => {
                      let data;
                      if (value.includes("all")) {
                        data = dataAirport?.map((item) => item?.value);
                      } else {
                        data = value;
                      }
                      props?.setFieldValue("airport_ids", data);
                      setSelectedAirportIds(data);
                    }}
                    onBlur={props?.setFieldTouched}
                  />
                  <Select2Multi
                    title={t("field.lounge")}
                    name="list_lounges"
                    options={dataLounges}
                    className="mb-2"
                    errors={props?.errors}
                    touched={props?.touched}
                    value={props?.values?.list_lounges}
                    onChange={(name, value) => {
                      props?.setFieldValue("list_lounges", value);
                    }}
                    onInputChange={(keyword) => {
                      debouncedSearchLounge(keyword);
                    }}
                    onBlur={props?.setFieldTouched}
                  />{" "}
                  <Textarea
                    title={t("promo.description")}
                    rows="4"
                    name="description"
                    errors={props?.errors}
                    touched={props?.touched}
                    value={props?.values?.description}
                    {...props?.getFieldProps("description")}
                  />
                  <DynamicButton
                    titleLeft={fileProp?.title}
                    subtitleLeft={fileProp?.subtitle}
                    description={fileProp?.description}
                    iconLeft="insert_drive_file"
                    toggle={openFileDialog}
                    error={fileProp?.message_error}
                  />
                  <input
                    type="file"
                    ref={fileRef}
                    className="d-none"
                    onChange={(e) => handleChange(e.target, props)}
                    accept="image/png, image/jpeg, application/pdf"
                  />
                </Container>
              </ModalBody>
              <ModalFooter>
                <ButtonModal
                  toggle={() => {
                    setShowModalConfirm(true);
                  }}
                  confirm={confirm}
                  confirmTitle={t("commons.save")}
                  disabledConfirm={loadingSubmit}
                  icon
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
      <ConfirmationModal
        show={showModalConfirm}
        toggle={() => setShowModalConfirm(false)}
        confirm={handleConfirm}
        isDelete="delete"
        cancelTitle={t("commons.no")}
        confirmTitle={t("commons.yes")}
        message={
          data
            ? `${t("confirm.edit")} <b>Promo</b>`
            : `${t("confirm.add")} <b>Promo</b>`
        }
        icon="info"
      />
    </>
  );
};

const mapStateToProps = ({ promo_lounge: { pending, data_post, data_put, error_message } }) => {
  return { pending, data_post, data_put, error_message };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(FormPromoModal));
