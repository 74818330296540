import React from "react";
import { useState, useEffect } from "react";
import { withTrans } from "../../../i18n/withTrans";
import { connect, useDispatch } from "react-redux";

import HeaderMenu from "../../../components/commons/menu/HeaderMenu";
import DetailButton from "../../../components/table/detailButton";
import MenuItem from "@mui/material/MenuItem";
import Loader from "../../../components/commons/Loader";
import Button from "../../../components/buttons/Button";
import SearchButton from "../../../components/buttons/SearchButton";
import Table from "../../../components/table";
import Select from "react-select";

// Service
import UserService from "../../../store/actions/master_data/user";
import UserModal from "./FormUserModal";
import ConfirmationModal from "../../../components/modal/ConfirmModal";
import DetailUserModal from "./DetailUserModal";
import { getUser } from "../../../utils/User";

const User = ({ t, pending }) => {
  const dispatch = useDispatch();
  const currentUser = getUser();
  console.log("user version 29/11/2024 17.00")

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [dataUser, setDataUser] = useState([]);
  const [dataUserRow, setDataUserRow] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [role, setRole] = useState("");
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const handleSearch = (value) => {
    setSearchText(value.length > 0 ? value : "");
  };
  const handleFilterStatus = (value) => {
    if (value) {
      setRole(value?.value);
    }
  };

  const showAddModal = () => {
    setAddModal(!addModal);
  };

  const showDetailModal = (data) => {
    setDetailModal(!detailModal);
    setDataUserRow(data ? data : []);
  };

  const handleEdit = (param) => {
    const callback = () => {
      handleRefresh();
      setEditModal(!editModal);
    };
    dispatch(UserService.put(param, callback));
  };

  const handleAdd = (param) => {
    const callback = () => {
      handleRefresh();
      setAddModal(!addModal);
    };
    dispatch(UserService.post(param, callback));
  };

  const showDeleteModal = (data) => {
    setDeleteModal(!deleteModal);
    setDataUserRow(data ? data : []);
  };

  const handleDelete = () => {
    const callback = () => {
      setPage(1);
      handleRefresh();
      setDeleteModal(!deleteModal);
    };
    if (dataUserRow) {
      const id = dataUserRow?.id;
      const name = dataUserRow?.name;
      dispatch(UserService.del(id, callback, name));
    }
  };

  const columns = [
    {
      Header: "NO.",
      accessor: "no",
    },
    {
      Header: t("field.name").toUpperCase(),
      accessor: "name",
    },
    {
      Header: "EMAIL",
      accessor: "email",
    },
    {
      Header: "ROLE",
      accessor: "role_name",
    },
    {
      Header: t("field.airport").toUpperCase(),
      Cell: ({ row }) => {
        const { original } = row;
        if(original?.airport_city && original?.airport_code && original?.airport_name) {
          let { airport_city, airport_code, airport_name, } = original;
          return `${airport_code}-${airport_city}-${airport_name}`;
        } else {
          return '-'
        }

      },
      show: currentUser.user.role_code !== "admin_bandara",
    },
    {
      Header: "",
      accessor: "id",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <DetailButton>
            <MenuItem onClick={() => showDetailModal(original)}>
              <span className="normal-title">{t("commons.detail")}</span>
            </MenuItem>
            {(currentUser.user.role_code !== "contact_center" &&
              currentUser.user.role_code !== "lead_contact_center") && (
              <MenuItem onClick={() => showDeleteModal(original)}>
                <span className="normal-title">{t("commons.delete")}</span>
              </MenuItem>
            )}
          </DetailButton>
        );
      },
    },
  ];

  const handleRefresh = () => {
    new Promise((resolve) => {
      let param = {
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
        role_code: role,
        search_text: searchText,
      };
      dispatch(UserService.get(param, resolve));
    }).then((res) => {
      setDataUser(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [page, searchText, role]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [searchText, role]);

  const customSelect = {
    control: (base, state) => ({
      ...base,
      cursor: "pointer",
      textAlign: "left",
      fontWeight: "700",
      background: "#ffff",
      borderRadius: "32px",
      padding: "1px 1px 1px 10px",
      height: "48px",
      minWidth: "200px",
      border: "2px solid #E5E5F0",
      borderColor: state.isFocused ? "#E5E5F0" : "#E5E5F0",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "#E5E5F0" : "#E5E5F0",
      },
    }),
    indicatorsContainer: (base) => ({
      ...base,
      marginRight: "7px",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: "#fff",
    }),
    option: (options, state) => ({
      ...options,
      cursor: "pointer",
      textAlign: "left",
      paddingLeft: "15px",
    }),
  };
  const optionsRole = [
    {
      label: `${t("commons.all")} Role`,
      value: "",
    },
    {
      label: "Admin Pusat",
      value: "admin_pusat",
    },
    {
      label: "Admin Bandara",
      value: "admin_bandara",
    },
    {
      label: "Corporate",
      value: "corporate",
    },
    {
      label: "Contact Center",
      value: "contact_center",
    },
    {
      label: "Supervisor",
      value: "supervisor",
    },
    {
      label: "Sales",
      value: "sales",
    },
    {
      label: "Finance",
      value: "finance",
    },
    {
      label: "Sales Bandara",
      value: "sales_bandara",
    },
    {
      label: "Finance Bandara",
      value: "finance_bandara",
    },
  ];
  return (
    <>
      {pending && <Loader sideContent loading={pending} />}
      <HeaderMenu title={t("field.user_back_office")}>
        <SearchButton
          placeholder={t("commons.enterKeyword")}
          toggle={(value) => handleSearch(value)}
        />
        <Select
          className="ml-3"
          placeholder={"Role"}
          isSearchable={false}
          options={optionsRole}
          styles={customSelect}
          onChange={(value) => handleFilterStatus(value)}
        />
        {currentUser.user.role_code !== "contact_center" && 
          currentUser.user.role_code !== "lead_contact_center" && (
          <>
            <div className="nav-separator mr-3 ml-3" />
            <Button
              title={t("commons.add") + " " + t("field.user")}
              leftIcon="add"
              rounded={true}
              variant="primary"
              onClick={showAddModal}
            />
          </>
        )}
      </HeaderMenu>
      <div className="content-container-side">
        <div className="overflow">
          <Table
            columns={columns?.filter((item) => item.show !== false)}
            data={dataUser ?? []}
            pagination={{ page, totalData, maxPage, toggle: setPage }}
          />
        </div>
      </div>

      <UserModal show={addModal} toggle={showAddModal} action={handleAdd} />

      <DetailUserModal
        show={detailModal}
        toggle={showDetailModal}
        data={dataUserRow}
        detailModal={setDetailModal}
        handleEdit={handleEdit}
        refresh={handleRefresh}
      />
      <ConfirmationModal
        show={deleteModal}
        toggle={showDeleteModal}
        confirm={handleDelete}
        param={`` + dataUserRow?.name}
        icon="info"
        isDelete
      />
    </>
  );
};

const mapStateToProps = ({ user: { pending, data } }) => {
  return { pending, data };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(User));
