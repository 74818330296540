import { actionPending, actionSuccess, actionError } from "../actionTypes";

import { toastSuccess, toastError } from "../../../components/commons/toast";

import API from "../API";
import { handleResponse } from "../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_BOOKING_PENDING = "GET_BOOKING_PENDING";
export const GET_BOOKING_SUCCESS = "GET_BOOKING_SUCCESS";
export const GET_BOOKING_ERROR = "GET_BOOKING_ERROR";
export const POST_BOOKING_REGULAR_PENDING = "POST_BOOKING_REGULAR_PENDING";
export const POST_BOOKING_REGULAR_SUCCESS = "POST_BOOKING_REGULAR_SUCCESS";
export const POST_BOOKING_REGULAR_ERROR = "POST_BOOKING_REGULAR_ERROR";
export const POST_BOOKING_RESCHEDULE_PENDING = "POST_BOOKING_RESCHEDULE_PENDING";
export const POST_BOOKING_RESCHEDULE_SUCCESS = "POST_BOOKING_RESCHEDULE_SUCCESS";
export const POST_BOOKING_RESCHEDULE_ERROR = "POST_BOOKING_RESCHEDULE_ERROR";
export const PUT_BOOKING_PENDING = "PUT_BOOKING_PENDING";
export const PUT_BOOKING_SUCCESS = "PUT_BOOKING_SUCCESS";
export const PUT_BOOKING_ERROR = "PUT_BOOKING_ERROR";
export const DELETE_BOOKING_PENDING = "DELETE_BOOKING_PENDING";
export const DELETE_BOOKING_SUCCESS = "DELETE_BOOKING_SUCCESS";
export const DELETE_BOOKING_ERROR = "DELETE_BOOKING_ERROR";
export const CALCULATE_PRICE_REG_PENDING =
  "CALCULATE_PRICE_REG_PENDING";
export const CALCULATE_PRICE_REG_SUCCESS =
  "CALCULATE_PRICE_REG_SUCCESS";
export const CALCULATE_PRICE_REG_ERROR =
  "CALCULATE_PRICE_REG_ERROR";
export const FIND_AVAIL_PRICE_PENDING =
  "FIND_AVAIL_PRICE_PENDING";
export const FIND_AVAIL_PRICE_SUCCESS =
  "FIND_AVAIL_PRICE_SUCCESS";
export const FIND_AVAIL_PRICE_ERROR =
  "FIND_AVAIL_PRICE_ERROR";

// URL: URL_{URL}
const BOOKING_URL = `v1/order`;
const BOOKING_REGULAR_URL = "v2/order/regular";
const BOOKING_CONTACT_CENTER_URL = "v2/order/regular/contact-center";
const BOOKING_CANCEL_URL = `v2/order/corporate/cancel`;
const BOOKING_RESCHEDULE_URL = `v1/order/reschedule/approve`;
const UPLOAD_REFUND_URL = "v1/order/refund/upload-proof";
const CALCULATE_PRICE_REG_URL = `v2/order/regular/calculate-price`;
const CALCULATE_PRICE_CONTACT_CENTER_URL = `v2/order/regular/calculate-price/contact-center`;
const FIND_AVAILIBILTY_PRICE = `v2/order/regular/find-availability-price`;
const EDIT_PASSENGER_URL = `v1/order/passenger/edit`;
const EDIT_PASSENGER_NOTES_URL = `v1/order/edit-passenger-notes`;
const EDIT_BOOKER_URL = '/v1/order/bookers/edit';
const EDIT_PAGING_URL = `v1/order/paging/edit`;
const ADD_ACTUAL_PAX_URL = `v1/order/actual-pax/add`;
const EDIT_ACTUAL_PAX_URL = `v1/order/actual-pax/edit`;
const DELETE_ACTUAL_PAX_URL = `v1/order/actual-pax/delete`;
const DELETE_PAYMENT_URL = `v1/order/payment_proof/delete`;
const RESCHEDULE_URL = `v1/order/schedule/edit`;
const UPLOAD_PAYMENT_URL = "v1/order/payment_proof/upload";
const EXPORT_DETAIL_BOOKING = "v1/order/export/pdf";

const lang = localStorage.getItem("joumpa_language");

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_BOOKING_PENDING));
  API.get(BOOKING_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_BOOKING_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
          passenger_type:
            lang === "id"
              ? item?.passenger_type_label
              : item?.passenger_type_label_eng,
          type_label:
            lang === "id"
              ? item?.type_label
              : item?.type_label_eng,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_BOOKING_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const post = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(POST_BOOKING_REGULAR_PENDING));
  API.post(BOOKING_REGULAR_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_BOOKING_REGULAR_SUCCESS, res));
      toastSuccess(
        lang === "id" ? "Berhasil Tambah Booking" : "Add Booking Successful"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_BOOKING_REGULAR_ERROR, err?.response));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const postContactCenter = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(POST_BOOKING_REGULAR_PENDING));
  API.post(BOOKING_CONTACT_CENTER_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_BOOKING_REGULAR_SUCCESS, res));
      toastSuccess(
        lang === "id" ? "Berhasil Tambah Booking" : "Add Booking Successful"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_BOOKING_REGULAR_ERROR, err?.response));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const upload_refund = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(POST_BOOKING_REGULAR_PENDING));
  API.post(UPLOAD_REFUND_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_BOOKING_REGULAR_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? "Berhasil Upload Bukti Refund"
          : "Uploaded Refund Proof Successful"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_BOOKING_REGULAR_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const put = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(PUT_BOOKING_PENDING));
  API.put(BOOKING_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_BOOKING_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? "Berhasil Ubah Data Harga Korporat"
          : "Change Corporate Price Data Successful"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_BOOKING_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const del = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_BOOKING_PENDING));
  API.post(BOOKING_CANCEL_URL + `?cancel_reason=${param.cancel_reason}&order_id=${param.order_id}`)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_BOOKING_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? `Reservasi telah dibatalkan`
          : "Booking has been cancelled"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_BOOKING_ERROR, err.response));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const approve_reschedule = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(POST_BOOKING_RESCHEDULE_PENDING));
  API.post(BOOKING_RESCHEDULE_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_BOOKING_RESCHEDULE_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? "Permintaan ubah jadwal berhasi disetujui!"
          : "Reschedule request approved!"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_BOOKING_RESCHEDULE_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const calculate_price = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(CALCULATE_PRICE_REG_PENDING));
  API.post(CALCULATE_PRICE_REG_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(CALCULATE_PRICE_REG_SUCCESS, res));

      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(CALCULATE_PRICE_REG_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const calculate_price_contact_center = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(CALCULATE_PRICE_REG_PENDING));
  API.post(CALCULATE_PRICE_CONTACT_CENTER_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(CALCULATE_PRICE_REG_SUCCESS, res));

      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(CALCULATE_PRICE_REG_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const findAvailPrice = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(FIND_AVAIL_PRICE_PENDING));
  API.get(FIND_AVAILIBILTY_PRICE, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(FIND_AVAIL_PRICE_SUCCESS, res));
      if (resolve) {
        let data = res.data.data;
        resolve({
          data: data,
        });
      }
    })
    .catch((err) => {
      dispatch(actionError(FIND_AVAIL_PRICE_ERROR, err?.response));
      if (reject) {
        reject(err?.response);
      }
    });
};

const editPassenger = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(PUT_BOOKING_PENDING));
  API.put(EDIT_PASSENGER_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_BOOKING_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? "Berhasil Ubah Data Penumpang"
          : "Change Passenger Data Successful"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_BOOKING_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const editPassengerNotes = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(PUT_BOOKING_PENDING));
  API.put(EDIT_PASSENGER_NOTES_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_BOOKING_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? "Berhasil Ubah Data Catatan Penumpang"
          : "Successfully Change Passenger Notes"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_BOOKING_ERROR, err?.response));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const editBooker = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(PUT_BOOKING_PENDING));
  API.put(EDIT_BOOKER_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_BOOKING_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? "Berhasil Ubah Data Pemesan"
          : "Change Booker Data Successful"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_BOOKING_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const addActualPax = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(POST_BOOKING_REGULAR_PENDING));
  API.post(ADD_ACTUAL_PAX_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_BOOKING_REGULAR_SUCCESS, res));
      toastSuccess(
        lang === "id" ? "Berhasil Tambah Data Penumpang" : "Add Passenger Data Successful"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_BOOKING_REGULAR_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const editActualPax = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(PUT_BOOKING_PENDING));
  API.put(EDIT_ACTUAL_PAX_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_BOOKING_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? "Berhasil Ubah Data Penumpang"
          : "Change Passenger Data Successful"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_BOOKING_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const delActualPax = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_BOOKING_PENDING));
  API.delete(DELETE_ACTUAL_PAX_URL + `?id=${param.id}`)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_BOOKING_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? `Data penumpang berhasil dihapus`
          : "Passenger data has been deleted"
      );
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_BOOKING_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const rescheduleBooking = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(PUT_BOOKING_PENDING));
  API.put(RESCHEDULE_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_BOOKING_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? "Berhasil Ubah Schedule Booking"
          : "Change Booking Schedule Successful"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_BOOKING_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const upload_payment_proof = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(POST_BOOKING_REGULAR_PENDING));
  API.put(UPLOAD_PAYMENT_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_BOOKING_REGULAR_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? "Berhasil Upload Bukti Pembayaran"
          : "Uploaded Payment Proof Successful"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_BOOKING_REGULAR_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const delPayment = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_BOOKING_PENDING));
  API.delete(DELETE_PAYMENT_URL + `?order_id=${param.id}`)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_BOOKING_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? `Data pembayaran berhasil dihapus`
          : "Payment data has been deleted"
      );
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_BOOKING_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};


const exportDetailBooking = (param, order_id) => (dispatch) => {
  dispatch(actionPending(GET_BOOKING_PENDING));
  API.get(EXPORT_DETAIL_BOOKING, { params: param, responseType: "blob", })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_BOOKING_SUCCESS, res));
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Service Delivery Form ${order_id}.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      dispatch(actionError(GET_BOOKING_ERROR));
      toastError(lang === 'id' ? "Gagal mengeksport data!" : "Export Unsuccessfull!");
    });
};

const editPaging = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(PUT_BOOKING_PENDING));
  API.put(EDIT_PAGING_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_BOOKING_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? "Berhasil Ubah Informasi Paging"
          : "Change Paging Information Successful"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_BOOKING_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const booking = {
  get,
  post,
  postContactCenter,
  put,
  del,
  upload_refund,
  approve_reschedule,
  calculate_price,
  calculate_price_contact_center,
  findAvailPrice,
  editPassenger,
  editBooker,
  editPaging,
  addActualPax,
  editActualPax,
  delActualPax,
  rescheduleBooking,
  upload_payment_proof,
  delPayment,
  exportDetailBooking,
  editPassengerNotes
};
export default booking;
