import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import debounce from "lodash.debounce";

import Card from "../../../components/cards/card";
import CardHeader from "../../../components/cards/cardHeader";
import CardBody from "../../../components/cards/cardBody";
import Input from "../../../components/forms/Input";
import InputPrepend from "../../../components/forms/InputPrepend";
import Select2 from "../../../components/forms/Select2";
import DynamicButton from "../../../components/forms/DynamicButton";
import { toastError } from "../../../components/commons/toast";
import { withTrans } from "../../../i18n/withTrans";

//SERVICE
import AirportService from "../../../store/actions/master_data/airport";
import AirlineService from "../../../store/actions/master_data/airline";
import ProductService from "../../../store/actions/master_data/product";
import LoungeService from "../../../store/actions/master_data/lounge";
import { numberWithCommas } from "../../../utils/Constants";

const FlightReservation = ({ formik, button, t, reg_price }) => {
  const dispatch = useDispatch();

  const [dataAirport, setDataAirport] = useState([]);
  const [dataAirportDomestic, setDataAirportDomestic] = useState([]);
  const [dataAirline, setDataAirline] = useState([]);
  const [searchTextAirport, setSearchTextAirport] = useState([]);
  const [searchAirportDomestic, setSearchAirportDomestic] = useState([]);
  const [searchTextAirline, setSearchTextAirline] = useState([]);
  const [dataLounge, setDataLounge] = useState([]);
  
  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchAirportDomestic,
        is_service: true,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      if (!(res && res?.data)) {
        return;
      }

      setDataAirportDomestic(res?.data);
    });
  }, [searchAirportDomestic, formik?.values.flight_type]);

  useEffect(() => {
    if (formik?.values?.flight_type === 1) {
      formik.setFieldValue("flight_type_code", 6);
    }
  }, [formik?.values?.flight_type]);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchTextAirport,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      setDataAirport(res?.data);
    });
  }, [searchTextAirport, formik?.values.flight_type]);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchTextAirline,
      };
      dispatch(AirlineService.get(param, resolve));
    }).then((res) => {
      setDataAirline(res?.data);
    });
  }, [searchTextAirline]);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999999,
      };
      dispatch(ProductService.get(param, resolve));
    }).then((res) => {
      formik.setFieldValue("data_product_list", res?.data);
    });
  }, []);

  useEffect(() => {
    const routeType = formik?.values?.airport_uniform_to !== "Indonesia"
      ? "international"
      : "domestic";

    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999,
        airport_id: formik?.values?.airport_id_from,
        route_type: routeType
      };

      if (formik?.values?.airport_id_from && formik?.values?.airport_id_to) {
        dispatch(LoungeService.get(param, resolve));
      }
    }).then((res) => {
      if (!res?.data?.length) return;

      setDataLounge(
        res?.data?.map((item) => (
          {
            label: (
              <>
                <div className="normal-title">{item.name}</div>
                <div className="d-flex justify-content-between">
                  <div className="col-md-4">
                    <div className="d-flex justify-content-between normal-title">
                      {t("commons.adult")}
                      <div className="text-primary">
                        {item?.price_adult
                          ? "Rp " + numberWithCommas(item?.price_adult)
                          : "Rp 0"}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex justify-content-between normal-title">
                      {t("commons.child")}
                      <div className="text-primary">
                        {item?.price_child
                          ? "Rp " + numberWithCommas(item?.price_child)
                          : "Rp 0"}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex justify-content-between normal-title">
                      {t("commons.infantLabel")}
                      <div className="text-primary">
                        {item?.price_infant
                          ? "Rp " + numberWithCommas(item?.price_infant)
                          : "Rp 0"}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ),
            value: item.id,
            name: item.name,
            price_adult: item.price_adult,
            price_child: item.price_child,
            price_infant: item.price_infant,
          }
        ))
      );
    });
  }, [
    formik?.values?.airport_id_from,
    formik?.values?.airport_id_to
  ]);

  useEffect(() => {
    if (
      formik?.values.flight_type >= 0 &&
      formik?.values?.airport_id_from &&
      formik?.values?.airport_id_to 
    ) {
      let isInternational =
        formik?.values?.airport_uniform_from !== "Indonesia" ||
        formik?.values?.airport_uniform_to !== "Indonesia";
      let services = [];

      if (formik?.values?.data_product_list?.length >= 1) {
        if (isInternational) {
          services = [
            ...services,
            {
              label: "MEET & GREET INTERNASIONAL",
              name: "Meet and Greet International",
              type: 1,
            },
          ];
        } else {
          services = [
            ...services,
            {
              label: "MEET & GREET DOMESTIC",
              name: "Meet and Greet Domestic",
              type: 2,
            },
          ];
        }
        formik.setFieldValue("list_available_services", services);
      }
    }
  }, [
    formik?.values?.airport_id_from,
    formik?.values?.airport_id_to,
    formik?.values.flight_type,
  ]);

  useEffect(() => {
    if (formik?.values?.product_type <= 0) {
      return;
    }

    const serviceInAvailableServices = 
      formik?.values?.list_available_services?.find(
        (item) => item?.type === formik?.values?.product_type
      );
    
    if (!serviceInAvailableServices) {
      formik.setFieldValue("product_type", 0);
    }
  }, [formik?.values?.list_available_services]);

  useEffect(() => {
    const currentDateTime = moment();
    const selectedDate = formik?.values?.date || currentDateTime.format('YYYY-MM-DD');
    const selectedTime = formik?.values?.time || currentDateTime.format('HH:mm');
    const minimalDateTime = currentDateTime.add(24, 'hours');
    const selectedDateTime = moment(`${selectedDate}, ${selectedTime}`, 'YYYY-MM-DD HH:mm');

    formik.setFieldValue('isGoShow', selectedDateTime.isBefore(minimalDateTime));
  }, [
    formik?.values?.date,
    formik?.values?.time,
  ]);

  const debouncedResults = debounce((value, type) => {
    if (value === "") return;
    if (type === "domestic") setSearchAirportDomestic(value);
    if (type === "inter") setSearchTextAirport(value);
  }, 500);

  const mapAirportData = (data, selectedAirport, list) => {
    const airports = [...list];
    data.forEach((airport) => {
      airports.push({
        value: airport?.id,
        label: `${airport?.code}-${airport?.city}-${airport?.name} ${
          airport?.uniform === "Indonesia" ? "" : "-" + airport?.uniform
        }`,
      });
    });
  
    //if there is no selectedAirport data in the list airports,
    //push selectedAirport so airport search results can still appear in the dropdown
    const index = airports.findIndex((x) => x?.value === selectedAirport?.value);
    if (index === -1 && selectedAirport) {
      airports.push(selectedAirport);
    }
  
    return airports?.filter((item) => Object.keys(item).length);
  };

  //Assign Array Data Airport International
  let airport_list = mapAirportData(
    dataAirport,
    formik?.values?.selectedAirport,
    []
  );

  //Assign Array Data Airport Domestic
  let airport_list_indo = mapAirportData(
    dataAirportDomestic.filter(airport => airport.uniform === "Indonesia"),
    formik?.values?.selectedAirportDomestic,
    []
  );

  const mapAirlineData = (data, selectedAirline, list) => {
    const airlines = [...list];
    data.forEach((airline) => {
      airlines.push({
        value: airline?.name,
        code: airline?.code,
        label: `${airline?.code} - ${airline?.name}`,
      });
    });

    //if there is no selectedAirline data in the list airlines,
    //push selectedAirline so airline search results can still appear in the dropdown
    const index = airlines.findIndex((x) => x?.value === selectedAirline?.value);
    if (index === -1 && selectedAirline) {
      airlines.push(selectedAirline);
    }
  
    return airlines?.filter((item) => Object.keys(item).length);
  };

  //Assign Array Data Airline
  let airline_list = mapAirlineData(
    dataAirline,
    formik?.values?.selectedAirline,
    []
  );

  return (
    <Row>
      <Col md={9}>
        <Card rounded>
          <CardHeader title={t("field.flightType")} />
          <CardBody>
            <div className="d-flex justify-content-left">
              <div className="col-4">
                <DynamicButton
                  titleLeft={t("field.departure")}
                  iconLeft="flight_takeoff"
                  active={formik.values.flight_type === 1}
                  errors={formik.errors}
                  touched={formik.touched}
                  onBlur={formik.setFieldTouched}
                />
              </div>
            </div>
          </CardBody>
        </Card>
        <Card rounded>
          <CardHeader title={t("field.flightInformation")} />
          <CardBody>
            <div className="d-flex justify-content-between">
              <div className="text-bold">{t("field.origin")}</div>
              <Col md={8}>
                <Select2
                  name="airport_id_from"
                  options={airport_list_indo}
                  errors={formik.errors}
                  touched={formik.touched}
                  value={formik.values?.airport_id_from}
                  onInputChange={(keyword) => {
                    debouncedResults(keyword, "domestic");
                  }}
                  onChange={(name, value) => {
                    if (formik.values?.airport_id_to === value) {
                      toastError(`${t("field.cantSameAirport")}!`);
                      return;
                    }

                    const index = dataAirportDomestic
                        ?.map(function (e) {
                          return e.id;
                        })
                        .indexOf(value);

                    formik.setFieldValue("airport_id_from", value);
                    formik.setFieldValue(
                      "airport_name_from",
                      `${dataAirportDomestic[index]?.code} - ${
                        dataAirportDomestic[index]?.city} - ${
                          dataAirportDomestic[index]?.name}`
                    );
                    formik.setFieldValue(
                      "airport_uniform_from",
                      dataAirportDomestic[index]?.uniform
                    );
                    formik?.setFieldValue(
                      "selectedAirportDomestic", 
                      {
                        value: value,
                        label: 
                          `${dataAirportDomestic[index]?.code} - ${
                            dataAirportDomestic[index]?.city} - ${
                              dataAirportDomestic[index]?.name} ${
                                dataAirportDomestic[index]?.uniform === "Indonesia"
                              ? ""
                              : `- ${dataAirportDomestic[index]?.uniform}`
                          }`,
                      }               
                    );
                    setDataLounge([]);
                    formik?.setFieldValue("lounge", "");
                  }}
                  onBlur={formik.setFieldTouched}
                />
              </Col>
            </div>
            <div className="d-flex justify-content-between">
              <div className="text-bold">{t("field.destination")}</div>
              <Col md={8}>
                <Select2
                  name="airport_id_to"
                  errors={formik.errors}
                  options={airport_list}
                  touched={formik.touched}
                  value={formik.values?.airport_id_to}
                  onInputChange={(keyword) => {
                    debouncedResults(keyword, "inter");
                  }}
                  onChange={(name, value) => {
                    if (formik.values?.airport_id_from === value) {
                      toastError(`${t("field.cantSameAirport")}!`);
                      return;
                    }

                    const index = dataAirport
                      ?.map(function (e) {
                        return e.id;
                      })
                      .indexOf(value);

                    formik.setFieldValue("airport_id_to", value);
                    formik.setFieldValue(
                      "airport_name_to",
                      `${dataAirport[index]?.code} - ${
                        dataAirport[index]?.city} - ${
                          dataAirport[index]?.name}`
                    );
                    formik.setFieldValue(
                      "airport_uniform_to",
                      dataAirport[index]?.uniform
                    );
                    formik?.setFieldValue(
                      "selectedAirport", 
                      {
                        value: value,
                        label:
                          `${dataAirport[index]?.code} - ${
                            dataAirport[index]?.city} - ${
                            dataAirport[index]?.name} ${
                            dataAirport[index]?.uniform === "Indonesia"
                              ? ""
                              : `- ${dataAirport[index]?.uniform}`
                          }`,
                      }
                    );
                    setDataLounge([]);
                    formik?.setFieldValue("lounge", "");
                  }}
                  onBlur={formik.setFieldTouched}
                />
              </Col>
            </div>
          </CardBody>
          <hr />
          <CardBody>
            <div className="row">
              <Col md={6}>
                <Select2
                  title={t("field.airline")}
                  name="airplane_name"
                  options={airline_list}
                  errors={formik.errors}
                  touched={formik.touched}
                  value={formik.values?.airplane_name}
                  onInputChange={(keyword) => {
                    if (keyword !== "") {
                      setSearchTextAirline(keyword);
                    }
                  }}
                  onChange={(name, value) => {
                    formik.setFieldValue("airplane_name", value);
                    let airplaneCode = airline_list.filter(function (el) {
                      return el.value == value;
                    });
                    formik.setFieldValue(
                      "airplane_code",
                      airplaneCode[0]?.code
                    );
                    formik.setFieldValue("selectedAirline", {
                      value: value,
                      code: airplaneCode[0]?.code,
                      label: `${airplaneCode[0]?.code} - ${value}`,
                    });
                  }}
                  onBlur={formik.setFieldTouched}
                />
              </Col>
              <Col md={6}>
                <div className="form-group mb-0">
                  <label className="form-label mb-1">
                    {t("field.flightNumber")}
                  </label>
                </div>
                <InputPrepend
                  name="airplane_number"
                  label={t("field.flightNumber")}
                  title={formik.values.airplane_code}
                  type="text"
                  width="25%"
                  mt="2"
                  touched={formik.touched}
                  {...formik.getFieldProps("airplane_number")}
                />
              </Col>
              <Col md={6}>
                <Input
                  title={t("field.departureDate")}
                  name="date"
                  type="date"
                  errors={formik.errors}
                  touched={formik.touched}
                  {...formik?.getFieldProps("date")}
                  value={formik.values?.date}
                />
              </Col>
              <Col md={6}>
                <Input
                  title={t("field.departureTime")}
                  name="time"
                  errors={formik.errors}
                  touched={formik.touched}
                  value={formik.values.time}
                  onChange={(e) => {
                    let value = e.target.value;
                    formik.setFieldValue("time", value);
                  }}
                  type="time"
                />
              </Col>
            </div>
          </CardBody>
        </Card>
        
        <Card rounded>
          <CardHeader title={t("field.lounge")} />
          <CardBody>
            <Select2
              clearable={!!formik.values?.lounge}
              name="lounge"
              errors={formik.errors}
              options={dataLounge}
              touched={formik.touched}
              value={formik.values?.lounge}
              onChange={(name, value) => {
                formik.setFieldValue("lounge", value);
                const index = dataLounge
                  ?.map(function (e) {
                    return e.value;
                  })
                  .indexOf(value);
                const lounge = {
                  name: value ? dataLounge[index]?.name : "",
                  id: value ? dataLounge[index]?.id : "",
                };
                formik.setFieldValue("orderExtras.lounge", lounge);
                formik.setFieldValue(
                  "lounge_price_adult",
                  dataLounge[index]?.price_adult
                );
                formik.setFieldValue(
                  "lounge_price_child",
                  dataLounge[index]?.price_child
                );
                formik.setFieldValue(
                  "lounge_price_infant",
                  dataLounge[index]?.price_infant
                );
              }}
              onBlur={formik.setFieldTouched}
            />
          </CardBody>
        </Card>
        {button()}
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ airport, corporate, airline, reg_price }) => {
  return { airport, corporate, airline, reg_price };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(
  connect(mapDispatchToProps, mapStateToProps)(FlightReservation)
);
