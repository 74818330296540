const Index = ({
  onClick,
  icon,
  colorIcon,
  title
}) => {
  return (
    <div
      className="d-flex justify-content-start align-items-center p-3"
      onClick={onClick}
    >
      <div
        className="d-flex justify-content-center align-items-center left-icon float-left p-2"
        style={{ border: `1px solid ${colorIcon ?? "#63ae5c"}`, borderRadius: '50%' }}
      >
        <span
          className="material-icons clickable"
          style={{ fontSize: "16px", color: colorIcon ?? "#63ae5c" }}
        >
          {icon}
        </span>
      </div>
      <div
        className="clickable normal-title text-extra-bold pl-0"
        style={{ color: colorIcon ?? "#63ae5c" }}
      >
        {title}
      </div>
    </div>
  )
}

export default Index