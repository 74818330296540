import { useState, useEffect } from "react";
import { withTrans } from "../../i18n/withTrans";
import { useDispatch } from "react-redux";
import { getUser } from "../../utils/User";

import SideMenu from "../../components/commons/menu/SideMenu";
import AssistantManagement from "./assistant_management";
import CustomerManagement from "./customer_management";
import UserManagement from "./user_management";
import UserLoungeManagement from "./user_lounge_management";

// Service
import AirportService from "../../store/actions/master_data/airport";
import { Role, RoleIash } from "../../utils/Constants";
const Index = ({ t }) => {
  const currentUser = getUser();
  const dispatch = useDispatch();

  const [show, setShow] = useState(
    currentUser?.user?.role_code === Role.Central_admin ||
    currentUser?.user?.role_code === Role.Contact_center ||
    currentUser?.user?.role_code === Role.Lead_Contact_center
      ? "user"
      : Object.values(RoleIash).includes(currentUser.user.role_code)
      ? "user_lounge"
      : "assistant"
  );
  const [dataAirport, setDataAirport] = useState([]);

  const handleAirport = () => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999,
        is_service: true
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      setDataAirport(res?.data);
    });
  };

  useEffect(() => {
    handleAirport();
  }, []);

  return (
    <div className="menu-container">
      <div className="grid-header">
        <div className="side-menu">
          <div className="menu-title text-extra-bold">{t("field.user")}</div>
          {Object.values(RoleIash).includes(currentUser.user.role_code) ? (
            <SideMenu
              title={t("field.user_back_office")}
              subtitle={t("user.list_user_back_office")}
              toggle={() => setShow("user_lounge")}
              active={show === "user_lounge"}
            />
          ) : (
            <>
              {(currentUser?.user?.role_code === Role.Central_admin ||
                currentUser?.user?.role_code === Role.Contact_center ||
                currentUser?.user?.role_code === Role.Lead_Contact_center) && 
              (
                <SideMenu
                  title={t("field.user_back_office")}
                  subtitle={t("user.list_user_back_office")}
                  toggle={() => setShow("user")}
                  active={show === "user"}
                />
              )}
              <SideMenu
                title={t("field.assistant")}
                subtitle={t("user.list_assistant")}
                toggle={() => setShow("assistant")}
                active={show === "assistant"}
              />
              {(currentUser?.user?.role_code === Role.Central_admin ||
                currentUser?.user?.role_code === Role.Contact_center) &&
              (
                <SideMenu
                  title={t("field.customer")}
                  subtitle={t("user.list_customer")}
                  toggle={() => setShow("customer")}
                  active={show === "customer"}
                />
              )}
            </>
          )}
        </div>
        {show === "assistant" && (
          <>
            <AssistantManagement dataAirport={dataAirport} />
          </>
        )}
        {show === "user" && (
          <>
            <UserManagement />
          </>
        )}
        {show === "customer" && (
          <>
            <CustomerManagement />
          </>
        )}
        {show === "user_lounge" && (
          <>
            <UserLoungeManagement />
          </>
        )}
      </div>
    </div>
  );
};

export default withTrans(Index);
