import React, { forwardRef, useRef, useEffect, useState } from "react";
import { useTable, useRowSelect } from "react-table";
import Pagination from "./pagination";
import "./table.scss";
import Loader from "../commons/Loader";
import { withTrans } from "../../i18n/withTrans";

const Index = ({
  t,
  columns,
  data,
  dark,
  hover,
  small,
  pagination,
  isLoading,
  minHeight,
  selection,
  selectionAlign = "left",
  customHeaderSelection,
  onSelection,
  selectionRow,
  footer = false,
  hideHeader,
  customFooterGroup
}) => {
  const IndeterminateCheckbox = forwardRef(({ value, row }, ref) => {
    const defaultRef = useRef();

    useEffect(() => {
      defaultRef.current.checked = selection?.indexOf(value) > -1;
    }, [selection, row]);

    return (
      <>
        <label className="checkbox-container" style={{ display: "initial" }}>
          <input
            type="checkbox"
            ref={defaultRef}
            value={value}
            onChange={(e) => handleSelect(e)}
          />
          <span className="checkmark"></span>
        </label>

        {/* <input
          ref={resolvedRef}
          type="checkbox"
          value={value}
          onChange={(e) => handleSelect(e)}
        /> */}
      </>
    );
  });

  useEffect(() => {
    let selectedData = selection ?? [];
    const index = selectedData.indexOf("all");
    if (index > -1) {
      data.forEach((item) => {
        const index = selectedData.indexOf(item.id || item.no);
        if (index === -1) {
          selectedData = [...selectedData, item.id || item.no];
        }
      });
      onSelection(selectedData);
    }
  }, [data]);

  const handleSelect = (event) => {
    let selectedData = selection ?? [];
    if (event.target.value === "all") {
      const index = selectedData.indexOf(event.target.value);
      if (index > -1) {
        selectedData = [];
      } else {
        selectedData = ["all", ...selectedData];
        data.forEach((item) => {
          selectedData = [...selectedData, item.id || item.no];
        });
      }
    } else {
      if (event.target.value) {
        const indexAll = selectedData.indexOf("all");
        if (indexAll > -1) {
          selectedData.splice(indexAll, 1);
        }
        const index = selectedData.indexOf(event.target.value);
        if (index > -1) {
          selectedData.splice(index, 1);
        } else {
          selectedData = [...selectedData, event.target.value];
        }
      }
    }
    let uniqArr = [...new Set(selectedData)];
    onSelection(uniqArr);
  };

  const {
    getTableProps,
    footerGroups,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data: data ? data : [{}],
    },

    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        if (selectionRow) {
          let headSelection = {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                {customHeaderSelection ? (
                  customHeaderSelection
                ) : (
                  <IndeterminateCheckbox value="all" />
                )}
              </div>
            ),
            Cell: ({ row }) => {
              const id = row?.original?.id;
              return (
                <div>
                  <IndeterminateCheckbox key={id} value={id} row={rows} />
                </div>
              );
            },
          };
          if (selectionAlign === "left") {
            return [headSelection, ...columns];
          } else if (selectionAlign === "right") {
            return [...columns, headSelection];
          } else {
            return [...columns];
          }
        } else {
          return [...columns];
        }
      });
    }
  );
    
  const customHeaderFooterGroups = 
    customFooterGroup
    ? [
        {
          headers: [...footerGroups[0].headers],
          getFooterGroupProps: () => ({}),
        },
      ] 
    : footerGroups;

  return (
    <div>
      <div
        className="table-responsive"
        style={{ position: "relative", minHeight: minHeight ?? "215px" }}
      >
        {isLoading && <Loader cardContent loading={isLoading} />}
        <table
          {...getTableProps()}
          className={`table ${dark && "table-dark"} ${hover && "table-hover"} ${small && "table-sm"
            }`}
        >
          {!hideHeader && (
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      style={{
                        verticalAlign: "top",
                        whiteSpace: "nowrap",
                        minWidth: column.minWidth ?? "",
                        maxWidth: column.maxWidth ?? "",
                        textAlign: column.textAlign ?? "left"
                      }}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          )}
          <tbody>
            {!isLoading && (
              <>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td 
                            {...cell.getCellProps()} 
                            colSpan={cell.column.colSpan ?? 1}
                            style={{ 
                              fontSize: 14, 
                              minWidth: cell.column.minWidth ?? "",
                              maxWidth: cell.column.maxWidth ?? "",
                              textAlign: cell.column.textAlign ?? "left"
                            }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                {rows?.length < 1 && !isLoading && (
                  <tr>
                    <td
                      colSpan={
                        headerGroups ? headerGroups[0]?.headers?.length + 1 : 3
                      }
                      style={{ textAlign: "center" }}
                    >
                      {t("commons.dataNotFound")}
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
          {footer && rows?.length > 0 && (
            <tfoot
              style={{
                borderRadius: '6px',
                border: '1px solid #E5E5F0'
              }}
            >
              {customHeaderFooterGroups?.map((group) => (
                <>
                <tr {...group?.getFooterGroupProps()}>
                  {group?.headers?.map((column) => {
                    return column?.columns?.length
                      ? (
                        column?.columns?.map((item) => (
                          <td
                            {...item?.getFooterProps()}
                            colSpan={column?.colSpanFooter ?? 1}
                            style={{
                              display: column?.hideColFooter ? 'none' : 'table-cell',
                              backgroundColor: '#F7F7FA'
                            }}
                          >
                            {item.render("Footer")}
                          </td>
                        ))
                      ) : (
                        <td
                          {...column?.getFooterProps()}
                          colSpan={column?.colSpanFooter ?? 1}
                          style={{
                            display: column?.hideColFooter ? 'none' : 'table-cell',
                            backgroundColor: '#F7F7FA'
                          }}
                        >
                          {column?.render("Footer")}
                        </td>
                      )
                  })}
                </tr>
                </>
              ))}
            </tfoot>
          )}
        </table>
      </div>
      <div style={{ width: "100%" }}>
        {pagination && !isLoading && (
          <Pagination
            page={pagination?.page}
            dataTotal={data?.length}
            recordTotal={pagination?.totalData}
            maxPage={pagination?.maxPage}
            toggle={pagination?.toggle}
            subInfoTotal={pagination?.subInfoTotal}
            subSubInfoTotal={pagination?.subSubInfoTotal}
            subInfo={pagination?.subInfo}
            subSubInfo={pagination?.subSubInfo}
          />
        )}
      </div>
    </div>
  );
};

export default withTrans(Index);
