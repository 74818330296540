import React, { useState, useRef, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import * as XLSX from "xlsx";
import { Divider } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import Card from "../../../components/cards/card";
import CardHeader from "../../../components/cards/cardHeader";
import CardBody from "../../../components/cards/cardBody";
import Input from "../../../components/forms/Input";
import CardFlush from "../../../components/cards/cardFlush";
import Checkbox from "../../../components/forms/Checkbox";
import Select2 from "../../../components/forms/Select2";
import Button from "../../../components/buttons/Button";
import ConfirmationModal from "../../../components/modal/ConfirmModal";
import ConfirmDeleteImport from "../../../components/modal/ConfirmModal";
import PassengerModal from "./modal/PassengerModal";
import { toastError } from "../../../components/commons/toast";
import { countries } from "../../../utils/Constants";
import { withTrans } from "../../../i18n/withTrans";
import { EXPORT_FILE_BASE64 } from "../../../utils/FormatExcelPassenger";
import { getLengthPassAdult, getLengthPassChild, getLengthPassInfant } from "../../../utils/Helper";

const PassengerReservation = ({ t, formik, button }) => {
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmModalDeleteImport, setConfirmModalDeleteImport] = useState(false);
  const [modalPass, setModalPass] = useState(false);
  const [indexPassenger, setIndexPassenger] = useState(null);
  const [dataCountries, setDataCountries] = useState(
    countries?.map((item) => ({
      label: item.name,
      value: item.name,
      countryCode: item.code?.toLowerCase(),
    }))
  );
  const fileRef = useRef();

  const passengersFromImport = formik?.values?.passenger_list?.filter(
    (item) => item?.isFromExcel === true
  );
  const passengersFromInput = formik?.values?.passenger_list?.filter(
    (item) => item?.isFromExcel !== true
  );

  let isValid = formik?.values?.passenger_list?.length;

  useEffect (() => {
    if (
      formik?.values?.passenger_as_booker == 1 ||
      formik?.values?.bookers === "firstPassenger"
    ) {
      if (formik?.values?.passenger_list?.length === 0) {
        formik?.setFieldValue("bookers_title", "");
        formik?.setFieldValue("bookers_name", "");
        formik?.setFieldValue("bookers_nationality", "");
        formik?.setFieldValue("bookers_email", "");
        formik?.setFieldValue("bookers_phone", "");
      } else {
        formik?.setFieldValue(
          "bookers_title",
          formik?.values?.passenger_list[0]?.title
        );
        formik?.setFieldValue(
          "bookers_name",
          formik?.values?.passenger_list[0]?.name
        );
        formik?.setFieldValue(
          "bookers_nationality",
          formik?.values?.passenger_list[0]
            ?.passenger_nationality
        );
        formik?.setFieldValue(
          "bookers_email",
          formik?.values?.passenger_list[0]?.email
        );
        formik?.setFieldValue(
          "bookers_phone",
          formik?.values?.passenger_list[0]?.phoneNumber
        );
        formik?.setFieldValue(
          "bookers_country_code",
          formik?.values?.passenger_list[0]?.countryCode
        );
        formik?.setFieldValue(
          "bookers_country_id",
          formik?.values?.passenger_list[0]?.countryId
        );
      }
    }
  }, [
    formik?.values?.passenger_as_booker,
    formik?.values?.bookers,
    formik?.values?.passenger_list[0],
    formik?.values?.passenger_list?.length
  ]);

  const showModalPass = () => {
    setModalPass(false);
  };

  const handleAddPassenger = (index, categoryPass) => {
    if (index !== null && index !== undefined) {
      if (categoryPass === "input") {
        const idxPassengerInput = formik?.values?.passenger_list?.findIndex(
          (item) => item === passengersFromInput?.find((item, x) => x === index)
        );
        setIndexPassenger(idxPassengerInput);
      } else {
        const idxPassengerImport = formik?.values?.passenger_list?.findIndex(
          (item) =>
            item === passengersFromImport?.find((item, x) => x === index)
        );
        setIndexPassenger(idxPassengerImport);
      }
    } else {
      let passenger_list = formik?.values?.passenger_list ?? [];
      formik?.setFieldValue("passenger_list", [
        ...passenger_list,
        {
          email: "",
          name: "",
          phoneNumber: "",
          title: "",
          passenger_nationality: "",
          passenger_date_of_birth: "",
        },
      ]);
      setIndexPassenger(null);
    }
    setModalPass(true);
  };

  const openFileDialog = () => {
    fileRef.current.value = "";
    fileRef.current.click();
  };

  const readExcel = (file) => {
    let headerNotValid = false;
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const workbook = XLSX.read(bufferArray, { type: "buffer" });
        const worksheet = workbook.Sheets["Passengers"];
        const header = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          defval: ""
        })?.[0];
        
        if (!header?.includes('Country Code')) {
          headerNotValid = true;
        }

        const data = XLSX.utils.sheet_to_json(worksheet, {
          raw: false,
          dateNF: "yyyy-mm-dd",
        });
        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      if (data?.length && !headerNotValid) {
        const convertedKeysData = data.map(el => 
          Object.fromEntries(Object.entries(el).map(([key, value]) => ([
            key.replace(/\s+/g, ""),
            value
          ])))
        );

        let list_passengers = convertedKeysData?.map((item) => {
          return {
            isFromExcel: true,
            title: item?.Title,
            name: item?.Name,
            passenger_date_of_birth: item?.Birthdate ? item?.Birthdate : "",
            passenger_nationality: item?.Nationality,
            email: item?.Email,
            countryId: 
              item?.CountryCode 
                ? item?.CountryCode?.split(" ")[1]?.replace("(", "").toLowerCase() 
                : "id",
            countryCode: 
              item?.CountryCode 
                ? item?.CountryCode?.split(" ")[0] 
                : 62,
            phoneNumber: 
              item?.CountryCode 
                ? `${item?.CountryCode?.split(" ")[0]}${
                    item?.Phone.charAt(0) == 0 ? item?.Phone?.substring(1) : item?.Phone
                  }` 
                : `62${item?.Phone}`,
          };
        });
        
        if (passengersFromInput?.length > 0) {
          passengersFromInput?.map((item) => {
            list_passengers = [item, ...list_passengers];
          });
        } 

        formik?.setFieldValue("passenger_list", list_passengers);
      } else {
        toastError(t("booking.errorFormatFilePass"));
      }
    });
  };

  const handleDownloadExcel = () => {
    let sliceSize = 1024;
    let byteCharacters = atob(EXPORT_FILE_BASE64);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);
      let bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    const FileSaver = require("file-saver");
    FileSaver.saveAs(
      new Blob(byteArrays, { type: "application/vnd.ms-excel" }),
      "Format Excel Passenger List.xlsx"
    );
  };

  const showConfirmaDelete = () => {
    setConfirmModal(!confirmModal);
  };

  const handleDeletePassengers = () => {
    formik?.setFieldValue(
      "passenger_list",
      formik?.values?.passenger_list?.filter(
        (item) => item?.isFromExcel === true
      )
    );
    fileRef.current.value = "";
    setConfirmModal(!confirmModal);
  };

  const showConfirmaDeleteImport = () => {
    setConfirmModalDeleteImport(!confirmModalDeleteImport);
  };

  const handleDeleteImport = () => {
    formik?.setFieldValue(
      "passenger_list",
      formik?.values?.passenger_list?.filter(
        (item) => item?.isFromExcel !== true
      )
    );
    fileRef.current.value = "";
    setConfirmModalDeleteImport(!confirmModalDeleteImport);
  };

  return (
    <>
      <Row>
        <Col md={9}>
          <Card className="rounded">
            <CardBody>
              <div className="d-flex align-items-center mb-4">
                <p className="text-bold text-primary mb-0">
                  {t("field.passengerInfo")}
                </p>
                <div className="ml-auto">
                  <Button
                    className="py-2 px-3"
                    title="Download Format Import"
                    type="button"
                    rounded={true}
                    variant="primary"
                    onClick={handleDownloadExcel}
                  />
                </div>
              </div>
              {passengersFromInput?.length ? (
                <div className="d-flex align-items-center">
                  <p
                    className="normal-title text-bold text-muted"
                  >
                    Data {t("field.passenger")}
                  </p>
                  <div
                    className="ml-auto normal-title text-bold text-danger"
                    onClick={showConfirmaDelete}
                  >
                    {`${t("commons.delete")} ${t("commons.all")}`}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {passengersFromInput?.map((item, index) => {
                return (
                  <CardFlush
                    list
                    padding="15px 0px"
                    label={item?.name}
                    key={index}
                    desc={
                      <div
                        className="d-flex justify-content-end clickable"
                        onClick={() => handleAddPassenger(index, "input")}
                      >
                        <span className="material-icons-outlined">
                          navigate_next
                        </span>
                      </div>
                    }
                    size={{ label: 10, desc: 2 }}
                  />
                );
              })}
              {passengersFromImport?.length ? (
                <div className="d-flex align-items-center mt-4">
                  <p
                    className="normal-title text-bold text-muted"
                  >
                    {t("field.labelImportResults")}
                  </p>
                  <div
                    className="ml-auto normal-title text-bold text-danger"
                    onClick={showConfirmaDeleteImport}
                  >
                    {`${t("commons.delete")} ${t("commons.all")}`}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {passengersFromImport?.map((item, index) => {
                return (
                  <CardFlush
                    list
                    padding="15px 0px"
                    label={item?.name}
                    key={index}
                    desc={
                      <div
                        className="d-flex justify-content-end clickable"
                        onClick={() => handleAddPassenger(index, "import")}
                      >
                        <span className="material-icons-outlined">
                          navigate_next
                        </span>
                      </div>
                    }
                    size={{ label: 10, desc: 2 }}
                  />
                );
              })}
              {formik?.values?.passenger_list?.length > 0 && (
                <div className="d-flex align-items-center mt-4">
                  <p
                    className="normal-title text-bold text-muted"
                  >
                    Total PAX
                  </p>
                  <div className="ml-auto">
                    <p
                      className="normal-title text-bold"
                    >
                      {`${getLengthPassAdult(formik?.values?.passenger_list)} ${t("commons.adult")}, ${
                        getLengthPassChild(formik?.values?.passenger_list)} ${t("commons.child")}, ${
                        getLengthPassInfant(formik?.values?.passenger_list)} ${t("commons.infantLabel")}`}
                    </p>
                  </div>
                </div>
              )}
              <Divider orientation="horizontal" />
              <div className="d-flex align-items-center mt-4">
                <div
                  className="col-md-5 pl-0"
                  onClick={() => handleAddPassenger()}
                >
                  <div className="left-icon float-left rounded-fill">
                    <span
                      className="material-icons clickable super-title text-primary"
                    >
                      person
                    </span>
                  </div>
                  <div
                    className="clickable normal-title text-extra-bold text-primary pt-3 pl-3"
                  >
                    {t("field.addPassenger")}
                  </div>
                </div>
                <div
                  className="col-md-2 normal-title text-bold text-muted"
                >
                  {t("commons.or")}
                </div>
                <div
                  className="col-md-5 d-flex justify-content-end pr-0"
                  onClick={openFileDialog}
                >
                  <div className="left-icon float-left rounded-fill">
                    <span
                      className="material-icons clickable super-title text-primary"
                    >
                      import_export
                    </span>
                  </div>
                  <div
                    className="clickable normal-title text-extra-bold text-primary pt-3"
                  >
                    Import File
                  </div>
                  <input
                    className="d-none"
                    type="file"
                    accept=".xlsx, .xls"
                    ref={fileRef}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      readExcel(file);
                    }}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="rounded">
            <CardHeader title={t("field.passengerNotes")} />
            <CardBody>
              <Input
                name="passenger_notes"
                type="text"
                errors={formik?.errors}
                touched={formik?.touched}
                placeholder={`${t("commons.add")} ${t("field.note")}`}
                value={formik?.values?.passenger_notes ?? ""}
                onChange={(e) => {
                  let value = e.target.value;
                  formik?.setFieldValue(`passenger_notes`, value);
                }}
              />
            </CardBody>
          </Card>
          <Card className="rounded">
            <CardHeader title={t("field.bookersInfo")} />
            <CardBody>
              {isValid ? (
                <div className="checkbox-wrapper">
                  <Checkbox
                    customClass="mt-2"
                    name="passenger_as_booker"
                    checked={formik?.values?.passenger_as_booker}
                    onChange={(e) => {
                      if (formik?.values?.passenger_as_booker) {
                        formik?.setFieldValue("passenger_as_booker", null);
                        formik?.setFieldValue("bookers_title", "");
                        formik?.setFieldValue("bookers_name", "");
                        formik?.setFieldValue("bookers_nationality", "");
                        formik?.setFieldValue("bookers_email", "");
                        formik?.setFieldValue("bookers_phone", "");
                      } else {
                        formik?.setFieldValue("passenger_as_booker", ["1"]);
                        formik?.setFieldValue(
                          "bookers_title",
                          formik?.values?.passenger_list[0]?.title
                        );
                        formik?.setFieldValue(
                          "bookers_name",
                          formik?.values?.passenger_list[0]?.name
                        );
                        formik?.setFieldValue(
                          "bookers_nationality",
                          formik?.values?.passenger_list[0]
                            ?.passenger_nationality
                        );
                        formik?.setFieldValue(
                          "bookers_email",
                          formik?.values?.passenger_list[0]?.email
                        );
                        formik?.setFieldValue(
                          "bookers_phone",
                          formik?.values?.passenger_list[0]?.phoneNumber
                        );
                        formik?.setFieldValue(
                          "bookers_country_code",
                          formik?.values?.passenger_list[0]?.countryCode
                        );
                        formik?.setFieldValue(
                          "bookers_country_id",
                          formik?.values?.passenger_list[0]?.countryId
                        );
                      }
                    }}
                    value={1}
                    label={t("field.bookerSameAsPassenger")}
                  />
                </div>
              ) : (
                <div></div>
              )}
              <div className="row">
                <div className="col-md-4">
                  <Select2
                    name="bookers_title"
                    type="text"
                    errors={formik.errors}
                    touched={formik.touched}
                    options={[
                      { label: t("commons.mr"), value: "Mr." },
                      { label: t("commons.mrs"), value: "Mrs." },
                      { label: t("commons.ms"), value: "Ms." },
                      { label: t("commons.child"), value: "Child" },
                      { label: t("commons.infant"), value: "Infant" },
                    ]}
                    {...formik.getFieldProps("bookers_title")}
                    value={isValid ? formik?.values?.bookers_title : ""}
                    onChange={(name, value) => {
                      formik?.setFieldValue(`bookers_title`, value);
                    }}
                    onBlur={formik?.setFieldTouched}
                    title={t("field.title")}
                  />
                </div>
                <div className="col-md-8">
                  <Input
                    name="bookers_name"
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik?.values?.bookers_name ?? ""}
                    onChange={(e) => {
                      let value = e.target.value;
                      formik?.setFieldValue("bookers_name", value);
                    }}
                    title={t("field.name")}
                    type="text"
                  />
                </div>
              </div>
              <Select2
                clearable={!!formik?.values?.bookers_nationality}
                name="bookers_nationality"
                title={t("field.nationality")}
                options={dataCountries}
                value={formik?.values?.bookers_nationality}
                onChange={(name, value) => {
                  formik?.setFieldValue(
                    "bookers_nationality", 
                    value
                  );
                }}
                errors={formik.errors}
                touched={formik.touched}
                onBlur={formik.setFieldTouched}
              />
              <Input
                name="bookers_email"
                type="email"
                errors={formik.errors}
                touched={formik.touched}
                value={isValid ? formik?.values?.bookers_email : ""}
                onChange={(e) => {
                  let value = e.target.value;
                  formik?.setFieldValue("bookers_email", value);
                }}
                title={t("field.email")}
              />
              <div className="form-group">
                <div className="form-label">{t("field.phone")}</div>
                <div className="input-wrapper w-100">
                  <PhoneInput
                    enableTerritories={true}
                    countryCodeEditable={false}
                    placeholder={t("field.placeHolderPhoneNumber")}
                    country={formik?.values?.bookers_country_id ?? "id"}
                    inputStyle={{ width: '100%' }}
                    enableSearch={true}
                    value={formik?.values?.bookers_phone}
                    onChange={(phone, country) => {
                      formik?.setFieldValue("bookers_country_code", country?.dialCode);
                      formik?.setFieldValue("bookers_phone", phone);
                      formik?.setFieldValue("bookers_country_id", country?.countryCode);
                    }}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
          {button()}
        </Col>
      </Row>
      <ConfirmationModal
        show={confirmModal}
        toggle={() => setConfirmModal(false)}
        confirm={() => handleDeletePassengers()}
        icon="info"
        param="semua data penumpang"
        isDelete
      />
      <ConfirmDeleteImport
        show={confirmModalDeleteImport}
        toggle={() => setConfirmModalDeleteImport(false)}
        confirm={() => handleDeleteImport()}
        icon="info"
        param="semua data import penumpang"
        isDelete
      />
      <PassengerModal
        show={modalPass}
        formik={formik}
        dataCountries={dataCountries}
        idx={indexPassenger}
        toggle={showModalPass}
      />
    </>
  );
};
const mapStateToProps = ({ corporate }) => {
  return { corporate };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(PassengerReservation)
);
