import { useState } from "react";

import SideMenu from "../../components/commons/menu/SideMenu";
import Airlines from "./airlines";
import Airport from "./airport";
import Corporate from "./corporate";
import { withTrans } from "../../i18n/withTrans";
import { getUser } from "../../utils/User";
import Lounge from "./lounge";
import { Role, RoleIash } from "../../utils/Constants";

const Index = ({ t, location }) => {
    const currentUser = getUser();
    const [show, setShow] = useState(
        location?.state?.tab ?? (Object.values(RoleIash).includes(currentUser.user.role_code) ? "lounge" : "airport")
    );

    return (
        <div className="menu-container">
            <div className="grid-header">
                <div className="side-menu">
                    <div className="menu-title text-extra-bold">
                        {t('masterData.masterData')}
                    </div>
                    {Object.values(RoleIash).includes(currentUser.user.role_code) ? (
                        <SideMenu
                            title="Lounge"
                            subtitle={t('masterData.loungeInfo')}
                            toggle={() => setShow("lounge")}
                            active={show === "lounge"}
                        />
                    ) : (
                        <>
                        <SideMenu
                            title={t('masterData.airport')}
                            subtitle={t('masterData.airportInfo')}
                            toggle={() => setShow("airport")}
                            active={show === "airport"}
                        />
                        {currentUser?.user.role_code !== Role.Sub_admin && (
                            <>
                            <SideMenu
                                title={t('masterData.airlines')}
                                subtitle={t('masterData.airlinesInfo')}
                                toggle={() => setShow("airlines")}
                                active={show === "airlines"}
                            />
                            <SideMenu
                                title={t('field.corporation')}
                                subtitle={t('masterData.corporateInfo')}
                                toggle={() => setShow("corporate")}
                                active={show === "corporate"}
                            />
                            </>
                        )}
                        </>
                    )}
                </div>
                {show === "airport" && (
                    <>
                        <Airport />
                    </>
                )}
                {show === "airlines" && (
                    <>
                        <Airlines />
                    </>
                )}
                {show === "corporate" && (
                    <>
                        <Corporate />
                    </>
                )}
                {show === "lounge" && (
                    <>
                        <Lounge />
                    </>
                )}
            </div>
        </div>
    );
};

export default withTrans(Index);
