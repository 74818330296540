import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";
import * as Yup from "yup";
import { withTrans } from "../../../i18n/withTrans";
import { toastError } from "../../../components/commons/toast";
import Select2 from "../../../components/forms/Select2";
import Input from "../../../components/forms/Input";
import ConfirmationModal from "../../../components/modal/ConfirmModal";
import ButtonModal from "../../../components/modal/ButtonModal";

const FormUserModal = ({
  data,
  t,
  show,
  toggle,
  handleAdd,
  handleEdit,
  data_post,
  data_put,
  error_message,
}) => {
  const formikRef = useRef();
  const title = (data ? t("commons.edit") : t("commons.add")) + " " + t("field.user_back_office");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const initial = data
    ? {
        id: data?.id,
        name: data?.name,
        email: data?.email,
        role_code: data?.role_code,
      }
    : {
        id: "",
        name: "",
        email: "",
        status: "",
        role_code: "",
      };

  const role_list = [
    { value: "admin_iash", label: "Administrator IASH" },
    { value: "customer_care_iash", label: "Customer Care IASH" },
  ];

  const validation = Yup.object().shape({
    name: Yup.string()
      .required(`${t("field.name")} ${t("commons.required")}`)
      .nullable(),
    email: Yup.string()
      .email(t("commons.invalidEmail"))
      .required(`Email ${t("commons.required")}`)
      .nullable(),
    role_code: Yup.string()
      .required(`Role ${t("commons.required")}`)
      .nullable(),
  });

  useEffect(() => {
    if (!error_message) return;

    setLoadingSubmit(false);
    toastError(error_message);
  }, [error_message]);

  useEffect(() => {
    if (data_post?.data?.status === 200) {
      setLoadingSubmit(false);
    }
  }, [data_post]);

  useEffect(() => {
    if (data_put?.data?.status === 200) {
      setLoadingSubmit(false);
    }
  }, [data_put]);

  return (
    <>
      <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {title}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          initialValues={initial}
          validationSchema={validation}
          onSubmit={async (values) => {
            setLoadingSubmit(true);
            data ? handleEdit(values) : handleAdd(values);
          }}
        >
          {(props) => (
            <Form>
              <Container>
                <ModalBody>
                  <div className="px-2">
                    <Input
                      title={t("field.name")}
                      label="name"
                      type="text"
                      errors={props?.errors}
                      touched={props?.touched}
                      {...props?.getFieldProps("name")}
                      value={props.values.name}
                    />

                    <Input
                      title="Email"
                      label="email"
                      type="email"
                      errors={props?.errors}
                      touched={props?.touched}
                      {...props?.getFieldProps("email")}
                      value={props.values.email}
                    />

                    <Select2
                      title="Role"
                      name="role_code"
                      options={role_list}
                      errors={props?.errors}
                      touched={props?.touched}
                      value={props?.values?.role_code}
                      onChange={(name, value) => {
                        props?.setFieldValue("role_code", value);
                      }}
                      onBlur={props?.setFieldTouched}
                      placeholder="Pilih Role"
                    />
                  </div>
                </ModalBody>
              </Container>
              <ModalFooter>
                <ButtonModal
                  toggle={toggle}
                  confirmTitle={t("commons.save")}
                  disabledConfirm={loadingSubmit}
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
      <ConfirmationModal
        cancelTitle={t("commons.no")}
        confirmTitle={t("commons.yes")}
        icon="info"
      />
    </>
  );
};

const mapStateToProps = ({ user_lounge: { data_post, data_put, error_message } }) => {
  return { data_post, data_put, error_message };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(FormUserModal));
