import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ButtonModal from "../../components/modal/ButtonModal";
import { withTrans } from "../../i18n/withTrans";

function ModalImage({ t, show, toggle, image, type }) {
  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 200 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          Banner{" "}
          <span className="close clickable" onClick={() => toggle()}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody className="my-0 mx-2">
          {image ? (
            <img
              src={image}
              alt="user"
              className="img img-fluid"
              width="100%"
            />
          ) : (
            <p>{t("commons.dataNotFound")}</p>
          )}
        </ModalBody>
        <ModalFooter>
          <ButtonModal
            toggle={() => toggle()}
            hideConfirm
            cancelTitle={t("commons.close")}
          />
        </ModalFooter>
      </Modal>
    </>
  );
}

export default withTrans(ModalImage);
