import { getErrorMessage } from "../../../../utils/Helper";
import {
    GET_USER_LOUNGE_PENDING,
    GET_USER_LOUNGE_SUCCESS,
    GET_USER_LOUNGE_ERROR,
    POST_USER_LOUNGE_PENDING,
    POST_USER_LOUNGE_SUCCESS,
    POST_USER_LOUNGE_ERROR,
    PUT_USER_LOUNGE_PENDING,
    PUT_USER_LOUNGE_SUCCESS,
    PUT_USER_LOUNGE_ERROR,
    DELETE_USER_LOUNGE_PENDING,
    DELETE_USER_LOUNGE_SUCCESS,
    DELETE_USER_LOUNGE_ERROR,
} from "../../../actions/master_data/user_lounge";

const lang = localStorage.getItem('joumpa_language');
const initialState = {
    pending: false,
    error: null,
    data_list: null,
    pending_post: false,
    data_post: null,
    pending_add: false,
    data_add: null,
    pending_put: false,
    data_put: false,
    pending_delete: false,
    data_delete: null
};

const user_lounge = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_LOUNGE_PENDING:
            return {
                ...state,
                pending: true,
            };
        case GET_USER_LOUNGE_SUCCESS:
            return {
                ...state,
                pending: false,
                data_list: action.data,
            };
        case GET_USER_LOUNGE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case POST_USER_LOUNGE_PENDING:
            return {
                ...state,
                pending_post: true,
                error_message: null,
            };
        case POST_USER_LOUNGE_SUCCESS:
            return {
                ...state,
                pending_post: false,
                data_post: action.data,
                error_message: null,
            };
        case POST_USER_LOUNGE_ERROR:
            return {
                ...state,
                pending_post: false,
                error: action.error,
                error_message: getErrorMessage(lang === 'en-US' ? 'en' : lang, action?.error?.data),
            };
        case PUT_USER_LOUNGE_PENDING:
            return {
                ...state,
                pending_put: true,
                error_message: null,
            };
        case PUT_USER_LOUNGE_SUCCESS:
            return {
                ...state,
                pending_put: false,
                data_put: action.data,
                error_message: null,
            };
        case PUT_USER_LOUNGE_ERROR:
            return {
                ...state,
                pending_put: false,
                error: action.error,
                error_message: getErrorMessage(lang === 'en-US' ? 'en' : lang, action?.error?.data),
            };
        case DELETE_USER_LOUNGE_PENDING:
            return {
                ...state,
                pending_delete: true,
            };
        case DELETE_USER_LOUNGE_SUCCESS:
            return {
                ...state,
                pending_delete: false,
                data_delete: action.data,
            };
        case DELETE_USER_LOUNGE_ERROR:
            return {
                ...state,
                pending_delete: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default user_lounge;
