import Tooltip from "../../commons/tooltip"

const Checkbox = ({
    name,
    checked,
    onChange,
    label,
    value,
    styleInput,
    info,
    warning,
    disabled,
    style,
    customClass,
    desc
}) => {

    return (
        <>
            <label className="checkbox-container">
                <p className="text-bold normal-title mb-0">{label}</p>
                {desc && (
                    <div className="normal-title">{desc}</div>
                )}
                {warning && checked && (
                    <div className="d-flex align-items-center mt-3">
                        <span
                            className="material-icons text-danger subtitle-text"
                        >
                            info_outlined
                        </span>
                        <span className="text-bold text-danger small-text"
                            style={{ marginLeft: "-10px" }}>
                            {warning}
                        </span>
                    </div>
                )}
                <input type="checkbox"
                    className={customClass}
                    style={style}
                    name={name}
                    value={value}
                    checked={checked ? checked?.includes(String(value)) : false}
                    onChange={onChange}
                    disabled={disabled}
                />
                <span className="checkmark" style={styleInput}></span>
            </label>
            {info && (
                <Tooltip
                    content={info}
                    direction="right"
                >
                    <span className="material-icons-round ml-1">info</span>
                </Tooltip>
            )}
        </>
    )
}

export default Checkbox