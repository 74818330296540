import React, { useRef } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Col,
  Row
} from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import Input from "../../../components/forms/Input";
import ButtonModal from "../../../components/modal/ButtonModal";
import DynamicForm from "../../../components/forms/DynamicForm";
import Select2 from "../../../components/forms/Select2";
import Select2Multi from "../../../components/forms/Select2Multi";
import { withTrans } from "../../../i18n/withTrans";
import InputPrepend from "../../../components/forms/InputPrepend";
import Checkbox from "../../../components/forms/Checkbox";
import { exceptionSymbolNumbering } from "../../../utils/Constants";

const MasterDataModal = ({
  t,
  show,
  toggle,
  action,
  data,
  confirm,
  pending,
  airport_list
}) => {
  const formikRef = useRef();

  const title =
    (data ? t("commons.edit") : t("commons.add")) + " " + t("field.lounge");

  const initial = data
    ? {
        id: data?.id,
        name: data?.name,
        airport_ids: data?.airport_list?.map((item) => item?.id).join().split(','),
        route_type: data?.route_type,
        price_adult: data?.price_adult,
        price_child: data?.price_child,
        price_infant: data?.price_infant,
        is_include_ppn: data?.is_include_ppn ? ["1"] : null,
        ppn_percentage: data?.ppn_percentage
      }
    : {
        name: "",
        airport_ids: null,
        route_type: "",
        price_adult: null,
        price_child: null,
        price_infant: null,
        is_include_ppn: null,
        ppn_percentage: null
      };

  const validation = Yup.object().shape({
    name: Yup.string().required(`${t("field.lounge")} ${t("commons.required")}`).nullable(),
    airport_ids: Yup.array().required(`${t("field.airport")} ${t("commons.required")}`).nullable(),
    route_type: Yup.string().required(`${t("field.routeType")} ${t("commons.required")}`).nullable(),
    price_adult: Yup.number()
      .min(1, `${t("field.price")} ${t("commons.required")}`)
      .required(`${t("field.price")} ${t("commons.required")}`)
      .nullable(),
    price_child: Yup.number()
      .min(1, `${t("field.price")} ${t("commons.required")}`)
      .required(`${t("field.price")} ${t("commons.required")}`)
      .nullable(),
    price_infant: Yup.number()
      .min(1, `${t("field.price")} ${t("commons.required")}`)
      .required(`${t("field.price")} ${t("commons.required")}`)
      .nullable(),
  });

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {title}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          initialValues={initial}
          validationSchema={validation}
          onSubmit={async (values, { setStatus }) => {
            setStatus();
            const param  = {
              ...values,
              price_adult: values.price_adult || 0,
              price_child: values.price_child || 0,
              price_infant: values.price_infant || 0,
              ppn_percentage: values.ppn_percentage || 0,
              is_include_ppn: values.is_include_ppn == 1 ? true : false,
            }
            action(param);
            await delay(3000);
          }}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <Container>
                  <Input
                    name="name"
                    errors={props.errors}
                    touched={props.touched}
                    {...props.getFieldProps("name")}
                    title={t("field.lounge")}
                    type="text"
                  />
                  <Select2Multi
                    title={t("field.airport")}
                    name="airport_ids"
                    options={airport_list}
                    errors={props?.errors}
                    touched={props?.touched}
                    value={props?.values?.airport_ids}
                    onChange={(name, value) => {
                      let data = value;
                      if (!data.length > 0) {
                        data = "";
                      }
                      props?.setFieldValue("airport_ids", data);
                    }}
                    onBlur={props?.setFieldTouched}
                  />
                  <Select2
                    title={t('field.routeType')}
                    name="route_type"
                    options={[
                      { value: "international", label: t("commons.international") },
                      { value: "domestic", label: t("commons.domestic") }
                    ]}
                    errors={props?.errors}
                    touched={props?.touched}
                    value={props?.values?.route_type}
                    onChange={(name, value) => {
                      props?.setFieldValue(`route_type`, value);
                    }}
                    onBlur={props?.setFieldTouched}
                  />
                  <Row style={{ marginTop: 20 }}>
                    <Col md={4} style={{ paddingRight: "0px" }}>
                      <div className="form-group" style={{ marginBottom: 0 }}>
                        <label className="form-label">
                          {`${t("field.price")} ${t("commons.adult")}`}
                        </label>
                      </div>
                    </Col>
                    <Col md={4} style={{ paddingLeft: "0px" }}>
                      <div className="form-group" style={{ marginBottom: 0 }}>
                        <label className="form-label">
                          {`${t("field.price")} ${t("commons.child")}`}
                        </label>
                      </div>
                    </Col>
                    <Col md={4} style={{ paddingLeft: "0px" }}>
                      <div className="form-group" style={{ marginBottom: 0 }}>
                        <label className="form-label">
                          {`${t("field.price")} ${t("commons.infantLabel")}`}
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <DynamicForm
                    notAlignedCenter
                    withoutDelete
                    input={`price_adult`}
                    nameCenter={`price_child`}
                    name={`price_infant`}
                    placeholderLeft={`${t("field.price")} ${t("commons.adult")}`}
                    placeholderCenter={`${t("field.price")} ${t("commons.child")}`}
                    placeholder={`${t("field.price")} ${t("commons.infantLabel")}`}
                    typeLeft="number"
                    type="number"
                    typeCenter="number"
                    currencyLeft
                    currencyCenter
                    currency
                    formik={props}
                    size={{ title: 4, center: 4, right: 4, }}
                  />
                  <Checkbox
                    style={{ marginTop: "30px" }}
                    name="is_include_ppn"
                    checked={props?.values?.is_include_ppn}
                    onChange={props?.handleChange}
                    value={1}
                    label={t("product.includes_ppn")}
                  />
                  <InputPrepend
                    mt="2"
                    name="ppn_percentage"
                    title="PPN %"
                    type="number"
                    min={0}
                    value={props?.values?.ppn_percentage}
                    onKeyDown={(e) =>
                      exceptionSymbolNumbering.includes(e.key) && e.preventDefault()
                    }
                    onChange={(data) => {
                      const val = data.target.value;
                      props?.setFieldValue(
                        "ppn_percentage",
                        val
                      );
                    }}
                  />
                </Container>
              </ModalBody>
              <ModalFooter>
                <ButtonModal
                  toggle={toggle}
                  confirm={confirm}
                  confirmTitle={t("commons.save")}
                  disabledConfirm={props.isSubmitting}
                  icon
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default withTrans(MasterDataModal);
