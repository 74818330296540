import { useEffect, useState } from "react";
import TooltipTaxBooking from "../tooltipTaxBooking";
import { withTrans } from "../../i18n/withTrans";
import { numberWithCommas } from "../../utils/Constants";

const SummaryCheckoutPrice = ({ t, category, keyTooltip, titlePrice, passengers, price, dataCheckout, isLounge  }) => {

  return (
    <>
      {category && (
        <div className="subtitle-text text-medium text-primary mt-2 mb-1">
          {category}
        </div>
      )}
      <div className="d-flex justify-content-between">
        <div>
          <div className="d-flex align-items-center normal-text">
            {titlePrice ?? t("field.price")}
            {(dataCheckout?.isIncludePpn || dataCheckout?.isIncludeConcession) && (
              <TooltipTaxBooking
                id={keyTooltip}
                include_ppn={dataCheckout?.isIncludePpn}
                include_concession={dataCheckout?.isIncludeConcession}
                ppn_percentage={dataCheckout?.ppnPercentage}
                concession_percentage={dataCheckout?.concessionPercentage}
                ppn_price={dataCheckout?.ppnPrice}
                concession_price={dataCheckout?.concessionPrice}
                dataOtherDPP={dataCheckout?.dpp}
              />
            )}
          </div>
          {dataCheckout?.isIncludePpn == false && (
            <div className="normal-text mt-1">
              {keyTooltip === "taxOthers" && dataCheckout?.dpp
                ? `PPN ${dataCheckout?.ppnPercentage}% x DPP Rp ${numberWithCommas(dataCheckout?.dpp)}`
                : `PPN ${dataCheckout?.ppnPercentage}%`}
            </div>
          )}
          {dataCheckout?.isIncludeConcession == false && (
            <div className="normal-text mt-1">
              {keyTooltip === 'taxOthers' && dataCheckout.dpp
                ? `Concession ${dataCheckout?.concessionPercentage}% x DPP Rp ${numberWithCommas(dataCheckout?.dpp)}`
                : `Concession ${dataCheckout?.concessionPercentage}%`}
            </div>
          )}
        </div>
        <div className="text-right">
          <div className="normal-text">
            {passengers ? `${passengers} x Rp ${numberWithCommas(price)}` : `Rp ${numberWithCommas(price)}`}
          </div>
          {dataCheckout?.isIncludePpn == false && (
            <div className="normal-text mt-1">
              {"Rp " + numberWithCommas(dataCheckout?.ppnPrice)}
            </div>
          )}
          {dataCheckout?.isIncludeConcession == false && (
            <div className="normal-text mt-1">
              {"Rp " + numberWithCommas(dataCheckout?.concessionPrice)}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default withTrans(SummaryCheckoutPrice);