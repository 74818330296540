import { actionPending, actionSuccess, actionError } from "../../actionTypes";

import { toastSuccess, toastError } from "../../../../components/commons/toast";

import API from "../../API";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_USER_LOUNGE_PENDING = "GET_USER_LOUNGE_PENDING";
export const GET_USER_LOUNGE_SUCCESS = "GET_USER_LOUNGE_SUCCESS";
export const GET_USER_LOUNGE_ERROR = "GET_USER_LOUNGE_ERROR";
export const POST_USER_LOUNGE_PENDING = "POST_USER_LOUNGE_PENDING";
export const POST_USER_LOUNGE_SUCCESS = "POST_USER_LOUNGE_SUCCESS";
export const POST_USER_LOUNGE_ERROR = "POST_USER_LOUNGE_ERROR";
export const PUT_USER_LOUNGE_PENDING = "PUT_USER_LOUNGE_PENDING";
export const PUT_USER_LOUNGE_SUCCESS = "PUT_USER_LOUNGE_SUCCESS";
export const PUT_USER_LOUNGE_ERROR = "PUT_USER_LOUNGE_ERROR";
export const DELETE_USER_LOUNGE_PENDING = "DELETE_USER_LOUNGE_PENDING";
export const DELETE_USER_LOUNGE_SUCCESS = "DELETE_USER_LOUNGE_SUCCESS";
export const DELETE_USER_LOUNGE_ERROR = "DELETE_USER_LOUNGE_ERROR";

// URL: URL_{URL}
const USER_URL = `v1/master_data/user-lounge`;

const lang = localStorage.getItem('joumpa_language')

const get = (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(GET_USER_LOUNGE_PENDING));
    API.get(USER_URL, { params: param, })
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_USER_LOUNGE_SUCCESS, res));
            if (resolve) {
                const records_total = res.data.total;
                let data = res.data.data.map((item, i) => ({
                    ...item,
                    no: i + 1 + (param?.page - 1) * param?.length,
                }));
                resolve({
                    data: data,
                    page: param?.page - 1,
                    totalCount: records_total,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(GET_USER_LOUNGE_ERROR));
            if (lang === "en-US" || lang === "en") {
                toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
            } else {
                toastError(err?.response?.data?.message);
            }
        });
};

const post = (param, resolve, callback) => (dispatch) => {
    dispatch(actionPending(POST_USER_LOUNGE_PENDING));
    API.post(USER_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(POST_USER_LOUNGE_SUCCESS, res));
            toastSuccess(lang === 'id' ? "Berhasil Tambah Data User" : "Successfully Added User Data");
            if (resolve) {
                resolve({
                    data: res.data.data,
                    message: res.data.message,
                    status: res.data.status,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(POST_USER_LOUNGE_ERROR, err?.response));
        });
};

const put = (param, resolve, callback) => (dispatch) => {
    dispatch(actionPending(PUT_USER_LOUNGE_PENDING));
    API.put(USER_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(PUT_USER_LOUNGE_SUCCESS, res));
            toastSuccess(lang === 'id' ? "Berhasil Ubah Data User" : "Successfully Updated User Data");
            if (resolve) {
                resolve({
                    data: res.data.data,
                    message: res.data.message,
                    status: res.data.status,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(PUT_USER_LOUNGE_ERROR, err?.response));
        });
};

const del = (param, callback, name) => (dispatch) => {
    dispatch(actionPending(DELETE_USER_LOUNGE_PENDING));
    API.delete(USER_URL + `?id=${param}`)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(DELETE_USER_LOUNGE_SUCCESS, res));
            toastSuccess(lang === 'id' ? `Berhasil Hapus Data User ${name}` : `Successfully Removed User User ${name}`);
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(DELETE_USER_LOUNGE_ERROR, err?.response));
            if (lang === "en-US" || lang === "en") {
                toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
            } else {
                toastError(err?.response?.data?.message);
            }
        });
};

const user_lounge = {
    get,
    post,
    put,
    del,
};
export default user_lounge;
