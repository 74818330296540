import React from "react";
import { useState, useEffect } from "react";
import { withTrans } from "../../i18n/withTrans";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";

import ModalFilter from "./modalFilter";
import RegularModal from "./FormPromoModal";
import DetailPromoModal from "./DetailPromoModal";
import Table from "../../components/table";
import Button from "../../components/buttons/Button";
import Loader from "../../components/commons/Loader";
import TableStatus from "../../components/table/status";
import DetailButton from "../../components/table/detailButton";
import SearchButton from "../../components/buttons/SearchButton";
import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import ConfirmationModal from "../../components/modal/ConfirmModal";
import LabelPlus from "../../components/table/labelPlus";

// Service
import PromoLoungeService from "../../store/actions/promo_lounge";
import AirportService from "../../store/actions/master_data/airport";
import MasterLoungeService from "../../store/actions/master_data/lounge";

const Promo = ({ t, pending_get }) => {
  const lang = localStorage.getItem('joumpa_language');
  const dispatch = useDispatch();
  const [dataAirport, setDataAirport] = useState([]);
  const [dataLounges, setDataLounges] = useState([]);
  const [searchLounge, setSearchLounge] = useState("");
  const [formModal, setFormModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [dataPromo, setDataPromo] = useState([]);
  const [dataPromoRow, setDataPromoRow] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [paramFilter, setParamFilter] = useState({
    airport_id: "",
    lounge_id: "",
    status: "",
    type: "",
    start_date: "",
    end_date: "",
  });

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999,
        is_service: true,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      setDataAirport(
        res?.data.map((airport) => ({
          value: airport?.id,
          label: `${airport?.code}-${airport?.city}-${airport?.name}`,
        }))
      );
    });
  }, []);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchLounge,
      };
      dispatch(MasterLoungeService.get(param, resolve));
    }).then((res) => {
      if (res) {
        setDataLounges(
          res?.data?.map((item) => ({
            label: item?.name,
            value: item?.id
          }))
        )
      }
    });
  }, [searchLounge]);

  const handleSearch = (value) => {
    setSearchText(value.length > 0 ? value : "");
  };

  const handleAdd = (param) => {
    const callback = () => {
      handleRefresh();
      setFormModal(!formModal);
    };
    dispatch(PromoLoungeService.post(param, callback));
  };

  const handleEdit = (param) => {
    const callback = () => {
      handleRefresh();
      setFormModal(!formModal);
    };
    dispatch(PromoLoungeService.put(param, callback));
  };

  const showDeleteModal = (data) => {
    setDeleteModal(!deleteModal);
    setDataPromoRow(data ? data : []);
  };

  const showFormModal = (data) => {
    setFormModal(!formModal);
    setDataPromoRow(data ?? null);
  };

  const showDetailModal = (data) => {
    setDetailModal(!detailModal);
    setDataPromoRow(data);
  };

  const handleDelete = (id) => {
    const callback = () => {
      handleRefresh();
      setPage(1);
      setDeleteModal(!deleteModal);
    };
    if (dataPromoRow) {
      const id = dataPromoRow?.id;
      dispatch(PromoLoungeService.del(id, callback));
    }
  };

  const columns = [
    {
      Header: "No",
      accessor: "no",
    },
    {
      Header: t("promo.promoName").toUpperCase(),
      accessor: "name",
    },
    {
      Header: t("promo.promoCode").toUpperCase(),
      accessor: "code",
    },
    {
      Header: t("promo.promoType").toUpperCase(),
      accessor: "type",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const promoType = lang === 'id' ? original?.type_label : original?.type_label_eng;
        return promoType;
      },
    },
    {
      Header: t("field.airport").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const idRow = original.id;
        return original?.list_airports_detail?.length 
          ? <LabelPlus data={original?.list_airports_detail} idRow={idRow} /> 
          : "-";
      },
      minWidth: 250
    },
    {
      Header: t("field.lounge").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const idRow = original.id;
        return original?.list_lounge_detail?.length 
          ? <LabelPlus data={original?.list_lounge_detail} idRow={idRow} /> 
          : "-";
      },
      minWidth: 250
    },
    {
      Header: t("promo.typePeriodPromo").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.is_service_date_period 
          ? t("report.serviceDate")
          : t("report.bookingDate");
      },
    },
    {
      Header: t("promo.startDate").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const date = moment(original?.period_start).format("DD/MM/YYYY");
        return `${date}`;
      },
    },
    {
      Header: t("promo.endDate").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const date = moment(original?.period_end).format("DD/MM/YYYY");
        return `${date}`;
      },
    },
    {
      Header: "STATUS",
      Cell: ({ row }) => {
        const { original } = row;
        let { valid } = original;
        return (
          <TableStatus
            status={valid === true ? t("promo.active") : t("promo.nonactive")}
            color={valid === true ? "primary" : "warning"}
          />
        );
      },
    },
    {
      Header: "",
      accessor: "id",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <DetailButton>
            <MenuItem onClick={() => showDetailModal(original)}>
              <span className="normal-title">{t("commons.detail")}</span>
            </MenuItem>
            <MenuItem onClick={() => showFormModal(original)}>
              <span className="normal-title">{t("commons.edit")}</span>
            </MenuItem>
            <MenuItem onClick={() => showDeleteModal(original)}>
              <span className="normal-title">{t("commons.delete")}</span>
            </MenuItem>
          </DetailButton>
        );
      },
    },
  ];

  const handleRefresh = () => {
    new Promise((resolve) => {
      let param = {
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
        search_text: searchText,
        manage_promo: true,
        airport_id: paramFilter?.airport_id !== "" ? paramFilter?.airport_id : null,
        lounge_id: paramFilter?.lounge_id !== "" ? paramFilter?.lounge_id : null,
        valid: paramFilter?.status !== "" ? paramFilter?.status : "",
        type: paramFilter?.type !== "" ? paramFilter?.type : "",
        start_date:
          paramFilter?.start_date !== ""
            ? moment(paramFilter?.start_date).format("YYYY-MM-DD")
            : "",
        end_date:
          paramFilter?.end_date !== ""
            ? moment(paramFilter?.end_date).format("YYYY-MM-DD")
            : "",
      };
      dispatch(PromoLoungeService.get(param, resolve));
    }).then((res) => {
      setDataPromo(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  const onApplyFilter = (param) => {
    setParamFilter({
      airport_id: param.airport_id,
      lounge_id: param.lounge_id,
      end_date: param.end_date ? moment(param.end_date).format("YYYY-MM-DD") : "",
      start_date: param.start_date ? moment(param.start_date).format("YYYY-MM-DD") : "",
      status: param.status,
      type: param.type,
    });
    setShowModalFilter(false);
  };

  const handleFilterModal = () => {
    setShowModalFilter(true);
  };

  useEffect(() => {
    handleRefresh();
  }, [page, searchText, paramFilter]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [searchText, paramFilter]);

  return (
    <>
      {pending_get && <Loader fullScreen loading={pending_get} />}
      <HeaderMenu title={t("promo.promoManagement")}>
        <SearchButton
          placeholder={t("commons.enterKeyword")}
          toggle={(value) => handleSearch(value)}
        />
        <Button
          title="Filter"
          size="sm"
          rounded={true}
          variant="button-light btn-lg ml-3"
          onClick={handleFilterModal}
        />
        <div className="nav-separator mr-3 ml-3" />
        <Button
          title={`${t("commons.add")} Promo`}
          leftIcon="add"
          rounded={true}
          variant="primary"
          onClick={() => showFormModal(null)}
        />
      </HeaderMenu>
      <div className="content-container-side">
        <div className="overflow">
          <Table
            columns={columns}
            data={dataPromo ?? []}
            pagination={{ page, totalData, maxPage, toggle: setPage }}
          />
        </div>
      </div>
      <ConfirmationModal
        show={deleteModal}
        toggle={showDeleteModal}
        confirm={handleDelete}
        messageData={`` + dataPromoRow?.name}
        icon="info"
        message={t("commons.areYouSureDelete")}
        isDelete
      />
      <RegularModal
        data={dataPromoRow}
        show={formModal}
        toggle={() => showFormModal(null)}
        actionAdd={handleAdd}
        actionEdit={handleEdit}
        refresh={handleRefresh}
        dataAirport={dataAirport}
        setSearchLounge={(value) => setSearchLounge(value)}
      />
      <DetailPromoModal
        show={detailModal}
        toggle={showDetailModal}
        data={dataPromoRow}
        dataAirport={dataAirport}
      />
      <ModalFilter
        show={showModalFilter}
        toggle={() => setShowModalFilter(false)}
        setSearchLounge={(value) => setSearchLounge(value)}
        dataAirport={dataAirport}
        dataLounges={dataLounges}
        filter={paramFilter}
        toggleFilter={(props) => {
          setParamFilter({
            status: props.status,
            type: props.type,
            start_date: props.start_date,
            end_date: props.end_date,
            airport_id: props.airport_id,
            lounge_id: props.lounge_id,
          });
        }}
        onApplyFilter={onApplyFilter}
      />
    </>
  );
};

const mapStateToProps = ({ promo_lounge: { pending_get, data } }) => {
  return { pending_get, data };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(Promo));
