import React, { useState, useEffect } from "react";
import { Container, Modal, ModalBody, ModalHeader } from "reactstrap";
import moment from "moment";
import CardFlush from "../../components/cards/cardFlush";
import SideMenu from "../../components/modal/SideModal";
import ModalImage from "./ModalImage";
import ModalUsage from "./ModalUsage";
import { numberWithCommas, promoLoungeType } from "../../utils/Constants";
import { withTrans } from "../../i18n/withTrans";

const DetailPromoModal = ({
  t,
  show,
  toggle,
  data,
  dataAirport,
  detailPromo,
}) => {
  const lang = localStorage.getItem('joumpa_language');
  const [showModalImage, setShowModalImage] = useState(false);
  const [showModalUsage, setShowModalUsage] = useState(false);

  const airport_list = data?.list_airports_detail?.map((airport) => ({
    value: airport?.id,
    label: `${airport?.name}`,
    name: `${airport?.name}`,
  }));

  const lounge_list = data?.list_lounge_detail?.map((item) => ({
    value: item?.id,
    label: `${item?.name}`,
    name: `${item?.name}`,
  }));

  const handleModalImage = () => {
    setShowModalImage(true);
  };

  const handleModalUsage = () => {
    setShowModalUsage(true);
  };

  return (
    <>
      <Modal isOpen={show}>
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {`Detail`}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Container className="px-3">
          <ModalBody className="px-4">
            <div className="h5 pb-30 text-extra-bold">{data?.name}</div>
            <div className="wrapper-detail-card">
              {
                (detailPromo = [
                  {
                    label: t("promo.promoType"),
                    desc: lang === 'id' ? data?.type_label : data?.type_label_eng,
                  },
                  {
                    label: t("promo.typePeriodPromo"),
                    desc: data?.is_service_date_period
                      ? t("report.serviceDate")
                      : t("report.bookingDate"),
                  },
                  {
                    label: data?.is_service_date_period
                      ? t("promo.serviceStartDate")
                      : t("promo.bookingStartDate"),
                    desc: moment(data?.period_start).format("DD/MM/YYYY"),
                  },
                  {
                    label: data?.is_service_date_period
                      ? t("promo.serviceEndDate")
                      : t("promo.bookingEndDate"),
                    desc: moment(data?.period_end).format("DD/MM/YYYY"),
                  },
                  {
                    label: t("promo.promoCode"),
                    desc: data?.code,
                  },
                  {
                    label: t("promo.promoValue"),
                    desc:
                      data?.discount_price > 0
                        ? "Rp " + numberWithCommas(data?.discount_price)
                        : data?.discount_percent + "%",
                  },
                ]
                  .concat(
                    data?.type === promoLoungeType.Specific
                      ? {
                          label: t("promo.numberOfPromo"),
                          desc: data?.quantity,
                        }
                      : []
                  )
                  .map((detail, index) => (
                    <CardFlush
                      list
                      key={index}
                      label={detail.label}
                      desc={detail.desc}
                      last={index === 6}
                    />
                  )))
              }
            </div>
            <div className="wrapper-detail-card">
              <CardFlush
                label={t("field.airport")}
                multipleList={airport_list}
              />
            </div>
            <div className="wrapper-detail-card">
              <CardFlush
                label={t("field.lounge")}
                multipleList={lounge_list}
              />
            </div>
            <div className="wrapper-detail-card">
              {
                (detailPromo = [
                  {
                    label: t("promo.description"),
                    desc: data?.description,
                  },
                ].map((detail, index) => (
                  <CardFlush
                    descList
                    key={index}
                    label={detail.label}
                    desc={detail.desc}
                  />
                )))
              }
            </div>
            <SideMenu
              title={t("promo.showBanner")}
              icon="image"
              toggle={() => handleModalImage()}
            />
            <SideMenu
              title={t("promo.showUsage")}
              icon="local_offer"
              toggle={() => handleModalUsage()}
            />
          </ModalBody>
        </Container>
      </Modal>
      <ModalImage
        show={showModalImage}
        toggle={() => setShowModalImage(false)}
        image={
          data?.banner
            ? process.env.REACT_APP_API_URL +
              data?.banner?.substr(1, data?.banner?.length)
            : null
        }
      />
      <ModalUsage
        show={showModalUsage}
        toggle={() => setShowModalUsage(false)}
        data={data?.list_orders}
        title={t("promo.usage")}
      />
    </>
  );
};

export default withTrans(DetailPromoModal);
