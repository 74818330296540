import React, { Component } from "react";
import Select from "react-select";
import { withTrans } from "../../../i18n/withTrans";

class Select2 extends Component {
  handleChange = (item) => {
    // this is going to call setFieldValue and manually update values.topcis
    if (this.props.onChange)
      this.props.onChange(this.props.name, item?.value ?? "");
  };

  render() {

    const { errors, touched, name, value, classForm } = this.props;

    const error = errors && touched ? errors[name] && touched[name] : false

    const isValid = errors && errors[name] && touched[name] ?
      (errors[name]).toLowerCase().includes('required') || (errors[name]).toLowerCase().includes('wajib') ?
        value !== null && value !== undefined && value !== "" : !(error) : true;

    const customStyles = {
      control: (base, state) => ({
        ...base,
        // state.isFocused can display different borderColor if you need it
        borderColor: state.isFocused ? "#ddd" : !isValid ? "red" : "#ddd",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused ? "#ddd" : !isValid ? "red" : "#ddd",
        },
        cursor: 'pointer',
        padding: '5px',
        borderRadius: '6px'
      }),
      indicatorSeparator: (base) => ({
        ...base,
        display: 'none',
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        color: '#000',
        visibility: this.props.disabled ? "hidden" : "visible",
        cursor: 'pointer',
        'svg': {
          width: '24px',
          height: '22px',
          '&hover': {
            color: '#0D51FF',
          },
        },
      }),
      option: (options, state) => ({
        ...options,
        cursor: "pointer",
        textAlign: "left",
        paddingLeft: "15px"
      })
    };

    return (
      <div className={`form-group ${classForm ?? ""}`}>
        {this.props?.title && (
          <label className="form-label">{this.props?.title}</label>
        )}
        <Select
          {...this.props}
          isSearchable={this.props.isSearchable}
          isDisabled={this.props.disabled}
          name={name}
          styles={customStyles}
          value={
            this.props.options?.find((obj) => {
              return obj?.value === value;
            }) || null
          }
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          options={this.props.options}
          placeholder={this.props.placeholder ?? `${this.props.t("commons.select")} ${this.props?.title ?? '...'}`}
          isClearable={this.props.clearable ?? false}
          classNamePrefix="select"
          className={"basic-single " + this.props.className}
        />
        {/* <ErrorMessage name={name} component="div" className="invalid-feedback" style={{ display: (isValid ? 'none' : 'block') }} /> */}
        <div
          className="invalid-feedback"
          style={{ display: isValid ? "none" : "block" }}
        >
          {errors[name]}
        </div>
      </div>
    );
  }
}

export default withTrans(Select2)
