import React from "react";
import { useState, useEffect } from "react";
import { withTrans } from "../../i18n/withTrans";
import { connect, useDispatch } from "react-redux";
import { getUser } from "../../utils/User";

import moment from "moment";
import ModalFilter from "./modalFilter";
import Table from "../../components/table";
import Loader from "../../components/commons/Loader";
import Button from "../../components/buttons/Button";
import TableStatus from "../../components/table/status";
import SearchButton from "../../components/buttons/SearchButton";
import HeaderMenu from "../../components/commons/menu/HeaderMenu";

// Service
import AuditService from "../../store/actions/audit_trail";
import AirportService from "../../store/actions/master_data/airport";
import MasterLoungeService from "../../store/actions/master_data/lounge";
import { Role, RoleIash } from "../../utils/Constants";

const Audit_trail = ({ t, pending }) => {
    const dispatch = useDispatch();
    const currentUser = getUser();

    const [dataAudit, setDataAudit] = useState([]);
    const [dataAirport, setDataAirport] = useState([]);
    const [dataLounges, setDataLounges] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [searchLounge, setSearchLounge] = useState("");
    const [totalData, setTotalData] = useState(null);
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [showModalFilter, setShowModalFilter] = useState(false);
    const [paramFilter, setParamFilter] = useState({
        start_date: "",
        end_date: "",
        status: "",
        role: "",
        lounge_id: "",
        airport_id: currentUser?.user.role_code === Role.Sub_admin 
            ? currentUser?.user.airport_id
            : ""
    });

    const handleSearch = (value) => {
        setSearchText(value.length > 0 ? value : "");
    };

    const columns = [
        {
            Header: "No",
            accessor: "no",
        },
        {
            Header: t("commons.time").toUpperCase(),
            Cell: ({ row }) => {
                const { original } = row;
                let { updated_at } = original;
                let date = moment(updated_at).format("DD/MM/yyyy");
                let time = moment(updated_at).format("HH:mm");
                return `${date}, ${time}`;
            },
        },
        {
            Header: t("field.name").toUpperCase(),
            accessor: "name"
        },
        {
            Header: t("commons.role").toUpperCase(),
            accessor: "role_name",
        },
        {
            Header: t("field.activity").toUpperCase(),
            accessor: "activity",
        },
        {
            Header: t("field.airport").toUpperCase(),
            Cell: ({ row }) => {
                const { original } = row;
                let { airport_detail } = original;
                return airport_detail ? `${airport_detail?.code} - ${airport_detail?.city}` : '-';
            },
            show: currentUser.user.role_code !== Role.Sub_admin,
        },
        {
            Header: t("field.lounge").toUpperCase(),
            Cell: ({ row }) => {
                const { original } = row;
                return original?.lounge ?? '-';
            },
            show: Object.values(RoleIash).includes(currentUser.user.role_code),
        },
        {
            Header: "STATUS",
            Cell: ({ row }) => {
                const { original } = row;
                let { status } = original;
                return (
                    <TableStatus
                        status={
                            status === 200
                                ? t("status.success")
                                : t("status.failed")
                        }
                        color={
                            status === 200
                                ? "primary"
                                : "danger"
                        }
                    />
                );
            },
        },
    ];

    const handleRefresh = () => {
        new Promise((resolve) => {
            let param = {
                page: page === 0 ? 1 : page ?? 1,
                length: 10,
                is_success: paramFilter?.status ?? "",
                role_code_user: paramFilter?.role ?? "",
                airport_id: paramFilter?.airport_id ?? "",
                lounge_id: paramFilter?.lounge_id ?? "",
                search_text: searchText ?? "",
                start_date: paramFilter?.start_date ?? "",
                end_date: paramFilter?.end_date ?? ""
            };
            dispatch(AuditService.get(param, resolve));
        }).then((res) => {
            setDataAudit(res?.data);
            setTotalData(res?.totalCount);
            setMaxPage(Math.ceil(res?.totalCount / 10));
        });
    };

    const onApplyFilter = (param) => {
        setParamFilter({
            start_date: param.start_date,
            end_date: param.end_date,
            status: param.status,
            role: param.role,
            airport_id: param.airport_id,
            lounge_id: param.lounge_id
        });
    };

    const handleFilterModal = () => {
        setShowModalFilter(true);
    };

    useEffect(() => {
        handleRefresh();
    }, [page, searchText, paramFilter]);

    useEffect(() => {
        setPage((prev) => (prev === null ? null : 1));
    }, [searchText, paramFilter]);

    useEffect(() => {
        new Promise((resolve) => {
          let param = {
            page: 1,
            length: 9999,
            is_service: true,
          };
          dispatch(AirportService.get(param, resolve));
        }).then((res) => {
          let list_airports = [];
          if (res) {
            list_airports = res?.data?.map((airport) => ({
              value: airport?.id,
              label: `${airport?.code}-${airport?.city}-${airport?.name} ${airport?.uniform === 'Indonesia' ? "" : "-" + airport?.uniform}`,
            }));
          }
          setDataAirport(list_airports);
        });
    }, []);

    useEffect(() => {
        new Promise((resolve) => {
          let param = {
            page: 1,
            length: 10,
            search_text: searchLounge,
          };
          dispatch(MasterLoungeService.get(param, resolve));
        }).then((res) => {
          if (res) {
            setDataLounges(
              res?.data?.map((item) => ({
                label: item?.name,
                value: item?.id
              }))
            )
          }
        });
      }, [searchLounge]);

    return (
        <>
            {pending && <Loader fullScreen loading={pending} />}
            <HeaderMenu title={t("field.audit_trail")}>
                <SearchButton
                    placeholder={t("commons.enterKeyword")}
                    toggle={(value) => handleSearch(value)}
                />
                <Button
                    title="Filter"
                    size="sm"
                    rounded={true}
                    variant="button-light btn-lg ml-3"
                    onClick={handleFilterModal}
                />
            </HeaderMenu>
            <div className="content-container-side">
                <div className="overflow">
                    <Table
                        columns={columns?.filter((item) => item.show !== false)}
                        data={dataAudit ?? []}
                        pagination={{ page, totalData, maxPage, toggle: setPage }}
                    />
                </div>
            </div>
            <ModalFilter
                show={showModalFilter}
                toggle={() => setShowModalFilter(false)}
                filter={paramFilter}
                toggleFilter={(props) => {
                   setParamFilter({
                       status: props.status,
                       start_date: props.start_date,
                       end_date: props.end_date,
                       role: props.end_role,
                       airport_id: props.airport_id,
                       lounge_id: props.lounge_id,
                   })
                }}
                onApplyFilter={onApplyFilter}
                dataAirport={dataAirport}
                dataLounges={dataLounges}
                setSearchLounge={(value) => setSearchLounge(value)}
            />
        </>
    );
};

const mapStateToProps = ({ audit_trail: { pending, data } }) => {
    return { pending, data };
};

const mapDispatchToProps = () => {
    return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(Audit_trail));
